// Flash notifications


$(function() {
    // handle flash of messages for html pages
    if ($('.alert-box').length > 0) {
        setTimeout(function(){
            $('.alert-box').fadeOut(300, function() { $(this).remove(); })
        }, 15000);
    }
});


function show_flash(message, message_type, container) {
    if (! message) return;
    var container = container || $('.flash_notifications');
    var close_button = '<button style="position:top; float:right; display: flex; justify-content: center;" class="close-button" aria-label="Dismiss ' + message_type + '" type="button" data-close > <span aria-hidden="true">&times;</span> </button>';
    var msg_div = $('<div data-alert class="callout alert-box '+message_type+'">'+ message + '</div>');
    msg_div.append(close_button); // attach the button to the notification, but doesn't affect it's absolute position...
    container.append(msg_div);
    setTimeout(function() {
        msg_div.fadeOut('fast', function() {
            $(this).remove();
        });
    }, 15000);
    $('#close-button').on('click', function() {
      $(this).remove();
    });
    $(document).on("click", ".close-button" ,function(){
        // make the button remove the specific div it is attached to.
        $(this).parent().remove();
    });
}


$.fn.show_flash_error = function(mess) {
    show_flash(mess, 'alert');
};
// just dubbling the error as alert (goal is to use alert)
$.fn.show_flash_alert = function(mess) {
    show_flash(mess, 'alert');
};

$.fn.show_flash_notice = function(mess) {
    console.log('show_flash_notice called - mess: '+mess);
    show_flash(mess, 'notice');
};


