$(function() {

    /* only for access */
    //if ($('#filters-action-panel').length === 0) return;

    $(document).on('click', '.cancel_access_edit', function(e) {
        e.preventDefault();

        var $tr = $(this).closest('tr');
        var data = {access:{}};
        data.authenticity_token = $("meta[name='csrf-token']").attr("content");
        data.cancel = true;

        $.ajax({
            url: $tr.attr('data-update-url'),
            type: 'put',
            dataType: 'script',
            data: data
        });
    });


    $(document).on('click', '.update_access_button', function(e) {
        e.preventDefault();

        var $tr = $(this).closest('tr');
        var data = {access:{}};
        $tr.find('select').each(function() {
            var $this = $(this);
            data.access[$this.attr('name')] = $this.val();
        });
        $tr.find('input').each(function() {
            var $this = $(this);
            if ($this.attr('type') == 'checkbox') {
                data.access[$this.attr('name')] = $this.prop('checked');
            } else {
                data.access[$this.attr('name')] = $this.val();
            }
        });
        data.authenticity_token = $("meta[name='csrf-token']").attr("content");
        data.index = $tr.attr('data-index');
        data.save = true;

        $.ajax({
            url: $tr.attr('data-update-url'),
            type: 'put',
            dataType: 'script',
            data: data
        });
    });

});

// handle click on a select box (select or unselect)
$(document).on('click', 'input.select_field_access', function(e) {
    var $container = $(this).closest('.access_row');
    if ($(this).is(':checked')) {
        $container.find('input#select_all_access').prop('checked', true);
    } else {
        // check if it is the last on this row
        var some_checked = false;
        var fields = $container.find('input.select_field_access');
        for (var i = 0, f; f = fields[i]; i++) {
            if ($(f).is(':checked')) {
                some_checked = true;
            }
        }
        $container.find('input#select_all_access').prop('checked', some_checked);
    }
});


// handle select_all
$(document).on('click', 'input#select_all_access', function(e) {
    var $container = $(this).closest('.access_row');
    var fields = $container.find('input.select_field_access');

    for (var i = 0, f; f = fields[i]; i++) {
        if ($(this).is(':checked')) {
            $(f).prop('checked', true);
        } else {
            $(f).prop('checked', false);
        }
    }
});


/////////////

// functions to handle sending search (reload of data list on page)
/*
 function sendSearch(searchTerm) {
 //alert("start of sendSearch.");
 var querystr = getSearchQueryStr();
 $('#search_form .loadRunning').show();
 $.get($("#search_form").attr("action"), querystr, null, "script")
 .fail(function() {
 $().show_flash_error('some error happened - sdfplease reload the page and try again...');
 })
 .always(function() {
 //alert("get always.");
 $('#search_form .loadRunning').hide();
 });
 history.replaceState(null, document.title, $("#search_form").attr("action") + (querystr && '?' + querystr));
 return false;
 };

 function getSearchQueryStr() {
 // disable fields that are not filled in so that they are not serialized
 $('#search_form').find('input[name="utf8"]').remove();
 $('#search_form input').each(function() {
 var $this = $(this);
 if (! $this.val()) $this.prop('disabled', true);
 });
 $('#search_form select').each(function() {
 var $this = $(this);
 //alert('got select val '+$this[0].selectedIndex);
 if ($this[0].selectedIndex <= 0) $this.prop('disabled', true);
 });
 var querystr = $("#search_form").serialize();
 // enable all input fields again
 $('#search_form input').each(function() {
 var $this = $(this);
 $this.prop('disabled', false);
 });
 $('#search_form select').each(function() {
 var $this = $(this);
 $this.prop('disabled', false);
 });
 return querystr;
 };
 */

// handle search (any page) in filter-action-panel
//$(function() {
    /* Searching (any page) via Ajax */
    //if ($('#filters-action-panel').length === 0) return;

//    $('#search_form input#search').livesearch({
//        searchCallback: sendSearch,
//        queryDelay: 250,
//        innerText: "Search",
//        minimumSearchLength: 2
//    });


//    $('#pagination.search-enabled a').click(function(e) {
//        var href = $(this).attr("href");
//        var page = href.match(/page=\d*/);
//        var querystr = getSearchQueryStr()+'&'+page;
//        var url = $("#search_form").attr("action");
//        //alert('got page: '+page+'    and querystring: '+querystr);
//        history.pushState(null, document.title, url+'?'+querystr);
//        $('#search_form .loadRunning').show();
//        $.get(url, querystr, null, "script")
//            .fail(function() {
//                $().show_flash_error('some error happened - please reload the page and try again...');
//            })
//            .always(function() {
//                //alert("get always.");
//                $('#search_form .loadRunning').hide();
//            });
//        return false;
//    });


    //$('#search_form .filter_content select, #search_form .filter_content input').on('change', sendSearch);

    //$('#search_form select').select2({
//    $('#service_access_user select').select2({
//        placeholder: 'All',
//        allowClear: true,
//        containerCssClass: $('#service_access_user select').attr('data-select2-container-css-class'),
//        dropdownCssClass: $('#service_access_user select').attr('data-select2-dropdown-css-class')
//    }).on('select2-clearing', function() {
//        //$(this).closest('.filter_container').find('.filter_hide').trigger('click');     // not needed
//    });


//});


