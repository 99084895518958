
#Trix = require('trix/dist/trix.js');
import Trix from "trix/dist/trix"


jQuery(document).on 'turbolinks:load', ->
  console.log('setting up trix...')

  # listen for file attachment events
  document.addEventListener 'trix-attachment-add', (event) ->
    console.log('trix-attachment add called')
    attachment = event.attachment
    console.log(attachment)
    # we can get editor from attachment.attachmentManager
    #attachment.setAttributes({
    #  url: data.image_url,
    #  href: data.url
    #})

  document.addEventListener 'trix-file-accept', (event) ->
    console.log('trix-file accept called')

  # not used for now
  $('.editor_image_attach').click (event) ->
    console.log('editor_image_attach called')
    $element = $(this).closest('.editor_container').find('trix-editor')[0]
    console.log($element)
    console.log($element.editor)
    file_content = $(this).closest('.image_insert').find('.image_url').val()
    console.log(file_content)
    attachment = new Trix.Attachment({ url: file_content, href: file_content, filename: 'myFile', contentType: 'image/jpg' })
    $element.editor.insertAttachment(attachment)

  $('.image_attach_to_editors').click (event) ->
    console.log('image_attach_to_editors called')
    attach = $(this)
    console.log(attach)
    $('trix-editor').each ->
      elem = $(this)
      console.log(elem)
      console.log(elem[0].editor)
      #attachment = new Trix.Attachment({ url: attach.attr('data-url'), href: attach.attr('data-url'), filename: attach.attr('data-filename'), contentType: attach.attr('data-content-type') })
      #attachment = new Trix.Attachment({ url: attach.attr('data-url'), filename: attach.attr('data-filename'), contentType: attach.attr('data-content-type') })
      attachment = new Trix.Attachment({
        url: attach.attr('data-url'),
        filename: attach.attr('data-filename'),
        contentType: attach.attr('data-content-type'),
        width: 300
      })
      # Attributes: url, href, filename, contentType, content, width, height, filesize, previewable
      # TODO - attach to bottom of document instead of top
      elem[0].editor.insertAttachment(attachment)

#// Listen to trix-attachment-add event so we'll get rid of the progress bar just for this demo
#// Here we should upload the attachment and handle progress properly
#document.addEventListener("trix-attachment-add", event => {
#  const { attachment } = event.attachment;
#
#  // Get rid of the progress bar
#  attachment.setUploadProgress(100)
#});


  # Get the Trix editor
  editor = document.querySelector('trix-editor');

  # Function to render every figure > img HTML code
  renderHtmlOutput = ->
    images = editor.querySelectorAll('figure > img')
    output = ''
    images.each (image) ->
      output += image.outerHTML.replace(/ /g, "\n  ") + "\n"
    document.getElementById('output-html').textContent = output


  # Instantiating an observer
  observer = new MutationObserver (mutations) ->
    console.log('observer called')
    mutations.forEach ({ type, target, attributeName }) ->
      # If the parent is a figure with an img target
      if (target.parentNode.tagName == 'FIGURE' && target.nodeName == 'IMG')
        console.log('we have mutation for FIGURE and IMG ')
        if (type == 'attributes')
          console.log('we have mutation for FIGURE and IMG - attributes ')
          switch attributeName
            # If we have attribute width
            when 'width'
              console.log('we have mutation for FIGURE and IMG - width')
              console.log(target)
              # Remove attribute width
              #target.setAttribute('width', '200')
              target.removeAttribute('width')
              # Add img-fluid only once
              target.classList.add('img-fluid')

            # If we have attribute height
            when 'height'
              # Remove attribute height
              target.removeAttribute('height')

        # Render images HTML code
        #renderHtmlOutput()


  # Observing Trix Editor
  editor2 = document.querySelector('trix-editor');
  if (editor2 != null)
    observer.observe(editor2, {
      attributes: true,
      childList: true,
      subtree: true
    })

