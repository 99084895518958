// functions to handle sending search (reload of data list on page)
//
// PLEASE NOTE: we need a structure where we have .ajax_search_container and as direct descendant the .filter-bar and in this the .ajax_search_form
// .ajax_search_container
//   .filter-bar
//     xxx
//         .ajax_search_form
//

function sendSearch(searchTerm, element) {
    var filter_bar = $(element).closest('.filter-bar');
    var searchForm = filter_bar.find('.ajax_search_form');
    console.log("in sendSearch - searchForm action is " + searchForm.attr('action'));
    if (typeof(searchForm.attr('action')) == 'undefined') {
        searchForm = $('.ajax_search_form');
    }
    //var searchForm = $(element).closest('.ajax_search_form');
    //console.log("in sendSearch - searchForm is "+searchForm);
    searchForm.find('.loadRunning').show();
    var querystr = getSearchQueryStr(searchForm);
    var path = searchForm.attr("action");
    if (querystr != '') {
        if (path.indexOf('?') > 0) {
            path = path +'&'+querystr;
        } else {
            path = path +'?'+querystr;
        }
    }
    console.log("sendSearch: querystr = "+ querystr);
    console.log("sendSearch: path = "+path);
    $.ajax({url: path, dataType: "script"})
        .fail(function() {
            $().show_flash_error('some error happened (search) - please reload the page and try again...');
        })
        .always(function() {
            searchForm.find('.loadRunning').hide();
        });
    // update history unless activity_history_search on the account page
    //if ((!searchForm.hasClass('.activity_history_search')) || ($('#accounts').length == 0)) {
        history.pushState(null, document.title, path);
    //}
    return false;
};

function sendPaginationSearch(elem) {
    var filter_bar = $(elem).closest('.filter-bar');
    var searchForm = filter_bar.find('.ajax_search_form');
    searchForm.find('.loadRunning').show();
    var href = elem.attr("href");
    var page = href.match(/page=\d*/);
    var querystr = getSearchQueryStr(searchForm);
    if (querystr == '') {
        querystr = page
    } else {
        querystr = querystr +'&'+page;
    }
    var path = searchForm.attr("action")+'?'+querystr;
    console.log("sendPaginationSearch: querystr = "+ querystr);
    console.log("sendPaginationSearch: path = "+path);
    history.pushState(null, document.title, path);
    //$.get(url, querystr, null, "script")
    $.ajax({url: path, dataType: "script"})
        .fail(function() {
            $().show_flash_error('some error happened (pagination) - please reload the page and try again...');
        })
        .always(function() {
            searchForm.find('.loadRunning').hide();
        });
};

function sendPaginationPagesizeSearch(elem) {
    var page = $(elem).attr("href").match(/page=\d*/);
    var pagesize = $(elem).attr("href").match(/pagesize=\d*/);
    console.log("sendPaginationPagesizeSearch: pagesize = "+ pagesize + ", current page = "+page);
    var searchContainer = $(elem).closest('.ajax_search_container');
    var searchForm = searchContainer.find('> .filter-bar .ajax_search_form');
    searchForm.find('.loadRunning').show();
    var querystr = getSearchQueryStr(searchForm);
    if (querystr == '') {
        querystr = page;
    } else {
        querystr = querystr +'&'+page;
    }
    var path = searchForm.attr("action")+'?'+querystr;
    querystr = querystr+'&'+pagesize;
    var path2 = searchForm.attr("action")+'?'+querystr;
    console.log("sendPaginationPagesizeSearch: querystr = "+ querystr);
    console.log("sendPaginationPagesizeSearch: path = "+path);
    console.log("sendPaginationPagesizeSearch: path2 = "+path2);
    history.pushState(null, document.title, path);
    $.ajax({url: path2, dataType: "script"})
        .fail(function() {
            $().show_flash_error('some error happened (pagination) - please reload the page and try again...');
        })
        .always(function() {
            searchForm.find('.loadRunning').hide();
        });
};

function getSearchQueryStr(searchForm) {
    // disable fields that are not filled in so that they are not serialized
    searchForm.find("input[name='utf8']").remove();
    //$('#search_form').find('input[name="utf8"]').remove();
    searchForm.find('input').each(function() {
        var $this = $(this);
        if (! $this.val()) $this.prop('disabled', true);
    });
    searchForm.find('select').each(function() {
        var $this = $(this);
        //alert('got select val '+$this[0].selectedIndex);
        if ($this[0].selectedIndex <= 0) $this.prop('disabled', true);
    });
    //var querystr = searchForm.serialize();
    var querystr = searchForm.serialize().replace(/[^&]+=&/g, '').replace(/&[^&]+=$/g, '').replace(/&?us_timezone=[^&]*/, '')
    // enable all input fields again
    searchForm.find('input').each(function() {
        var $this = $(this);
        $this.prop('disabled', false);
    });
    searchForm.find('select').each(function() {
        var $this = $(this);
        $this.prop('disabled', false);
    });
    return querystr;
};


function updateNumSelected(e) {
    // get number of ids selected
    // TODO - rewrite to find closest .ajax_search_container and then .find('.ajax_search_form')
    var searchContainer = e.closest(".ajax_search_container");
    var searchForm = searchContainer.find(".ajax_search_form");
    var $ids = searchForm.find('input#ids');
    var val = $ids.attr('value');
    var val_array = [];
    if (val) {
        val_array = val.split(',');
    }
    var num_selected = val_array.length;
    if (num_selected == 0) {
        $('.select_all_info').hide();
        $('.select_all_clear').hide();
        //$('input#select_all').prop('checked', false);
    } else {
//        $('.select_all_count').html(num_selected+' selected');
        $('.select_all_count').html(num_selected);
        $('.select_all_info').show();
        $('.select_all_clear').show();
    }
};

// handle search (any page) in filter-action-panel - that have ajax_search_form class
Rs.ready(function() {
    // Searching (any page) via Ajax

    $('.ajax_search_form input#search').livesearch({
        searchCallback: sendSearch,
        queryDelay: 250,
        innerText: "Search",
        minimumSearchLength: 2
    });


    $('#pagination.search-enabled a').click(function(e) {
        sendPaginationSearch($(this));
        return false;
    });

    // handle buttons that need selected items or all (PDF and JSON export)
    $('.select-enabled-link a').click(function(e) {
        var searchContainer = $(this).closest('.ajax_search_container');
        var searchForm = searchContainer.find('> .filter-bar .ajax_search_form');
        //var searchForm = $(e).closest('.ajax_search_form');
        //var $ids = $('#search_form').find('input#ids');
        var $ids = searchForm.find('input#ids');
        var val = $ids.attr('value');
        console.log("select-enabled-link - click: val = "+ val);
        //if (val == null || val.length == 0) {
        //    e.preventDefault();
        //    alert('Please select one or more items before using this feature');
        //    return false;
        //}
        var href = $(this).attr("href");
        var i = href.indexOf('?');
        if (i > 0) {
            href = href.slice(0,i);
        }

        if (val != null && val.length > 0) {
            href = href+'?ids='+val;
        } else {
            // If no ids are selected, check to see if there's filters set up
            var querystr = getSearchQueryStr(searchForm);
            console.log('got href: ' + href + '    and querystring: ' + querystr);
            href = href + '?' + querystr;

        }
        $(this).attr("href", href);
        return true;   // continue with default behavior (or click link)
    });

    // handle buttons that need selected items (Schedules Start, Stop, DeleteAll)
    $('.select-some-enabled-link a').click(function(e) {
        var searchContainer = $(this).closest('.ajax_search_container');
        var searchForm = searchContainer.find('> .filter-bar .ajax_search_form');
        //var searchForm = $(e).closest('.ajax_search_form');
        //var $ids = $('#search_form').find('input#ids');
        var $ids = searchForm.find('input#ids');
        var val = $ids.attr('value');
        console.log("select-some-enabled-link - click: val = "+ val);
        if (val == null || val.length == 0) {
            e.preventDefault();
            alert('Please select one or more items before using this feature');
            return false;
        }
        var href = $(this).attr("href");
        var i = href.indexOf('?');
        if (i > 0) {
            href = href.slice(0,i);
        }
        //var querystr = getSearchQueryStr();
        //alert('got href: '+href+'    and querystring: '+querystr);
        //href = href+'?'+querystr;
        href = href+'?ids='+val;
        $(this).attr("href", href);
        return true;   // continue with default behavior (or click link)
    });

    // handle pagination pagesize forms that need searchQueryStr for filtering (transfer change status and copy transfer)
    //$('form.pagination-pagesize-search-enabled-form').on('submit', function(e) {
    $('.pagination-pagesize-bar .input-group-button a').click(function(e) {
        sendPaginationPagesizeSearch($(this));
        return false;
    });

    // handle forms that need searchQueryStr for filtering (transfer change status and copy transfer)
    $('form.search-enabled-form').on('submit', function(e) {
        $(this).find('input[name="utf8"]').remove();
        var searchContainer = $(this).closest('.ajax_search_container');
        var searchForm = searchContainer.find('> .filter-bar .ajax_search_form');
        var $ids = searchForm.find('input#ids');
        //var $ids = $('#search_form').find('input#ids');
        var val = $ids.attr('value');
        if (val == null || val.length == 0) {
            e.preventDefault();
            alert('Please select one or more Transfers before using this feature');
            return false;
        }
        // we could just send the ids as this is all we use now
        var parms = $(this).serialize();
        //var querystr = getSearchQueryStr();
        var querystr = 'ids='+val;
        querystr = parms+(querystr && '&' + querystr);
        var $parent = $(this).closest('.panel_container_ajax');
        var cid = $parent.attr('id');
        $('#'+cid+' .loadRunning').show();

        $.post($(this).attr("action"), querystr, null, "script")
            .fail(function() {
                $().show_flash_error('some error happened - please reload the page and try again...');
            })
            .always(function() {
                //alert("get always.");
                $('#'+cid+' .loadRunning').hide();
            });
        return false;
    });

    //$('#search_form .filter_content select, #search_form .filter_content input').on('change', sendSearch);
    $('.ajax_search_form .filter_content select, .ajax_search_form .filter_content input').on('change', sendSearch);

    $('.ajax_search_form .filter_content select').each(function() {
        var $this = $(this);
        var placeholder = $this.attr('data-select2-placeholder');
        var container_css_class = $this.attr('data-select2-container-css-class');
        var dropdown_css_class = $this.attr('data-select2-dropdown-css-class');
        $this.select2({
            placeholder: placeholder,
            allowClear: true,
            containerCssClass: container_css_class,
            dropdownCssClass: dropdown_css_class
        }).on('select2-clearing', function () {
            $(this).closest('.filter_container').find('.filter_hide').trigger('click');
        });
    });

    $(document).on('rs.select2-cleared', function(e) {
        var fc = $(e.input).closest('.filter_container')
        fc.find('.filter_content').css({
            'display': 'none'
        });
        fc.find('.filter_hide').hide();
        fc.find('.filter_show').show();
    });

    //$('#search_form .filter_container .filter_show').click(function() {
    $('.ajax_search_form .filter_container .filter_show').click(function() {
        var fc = $(this).closest('.filter_container')
        fc.find('.filter_content').css({
            'display': 'inline'
        });
        fc.find('.filter_show').css({'display': 'none'});
        fc.find('.filter_hide').css({'display': 'inline'});
    });
    //$('#search_form .filter_container .filter_hide').click(function() {
    $('.ajax_search_form .filter_container .filter_hide').click(function() {
        var fc = $(this).closest('.filter_container')
        fc.find('input[type="hidden"], select').select2('val', '');
        fc.find('.filter_content').css({
            'display': 'none'
        });
        fc.find('.filter_hide').css({'display': 'none'});
        fc.find('.filter_show').css({'display': 'inline'});
        sendSearch();
    });


    // handle select_all_clear
    $('a.select_all_clear').click(function(e) {
        var searchContainer = $(this).closest('.ajax_search_container');
        var searchForm = searchContainer.find('> .filter-bar .ajax_search_form');
        //var searchForm = searchContainer.find('.ajax_search_form');
        var fields = searchContainer.find('input.select_field');
        //var fields = $(document).find('input.select_field');
        for (var i = 0, f; f = fields[i]; i++) {
            if ($(f).is(':checked')) {
                $(f).click();
            }
        }
        var $ids = searchForm.find('input#ids');
        //var $ids = $('#search_form').find('input#ids');
        $ids.attr('value','');
        $(this).closest('.action-bar').find('.select_all_info').hide();
    });

});

// handle click on a select box (select or unselect)
$(document).on('click', 'input.select_field', function(e) {
    var item_id = $(this).attr('id').split('_').pop();
    var searchContainer = $(this).closest(".ajax_search_container");
    var searchForm = searchContainer.find(".ajax_search_form");
    var $ids = searchForm.find('input#ids');
    var val = $ids.attr('value');
    var val_array = [];
    if (val) {
        val_array = val.split(',');
    }
    var idx = $.inArray(item_id, val_array);

    if ($(this).is(':checked')) {
        if (idx == -1) {
            val_array.push(item_id);
            $ids.attr('value',val_array.join(','));
        }
        $('input#select_all').prop('checked', true);
    } else {
        if (idx > -1) {
            val_array.splice(idx, 1);
            $ids.attr('value', val_array.join(','));
        }
        // check if it is the last on this page
        var some_checked = false;
        var fields = $(document).find('input.select_field');
        for (var i = 0, f; f = fields[i]; i++) {
            if ($(f).is(':checked')) {
                some_checked = true;
            }
        }
        $('input#select_all').prop('checked', some_checked);
    }
    updateNumSelected($(this));
});


// handle select_all
$(document).on('click', 'input#select_all', function(e) {
    var searchContainer = $(this).closest(".ajax_search_container");
    var searchForm = searchContainer.find(".ajax_search_form");
    var $ids = searchForm.find('input#ids');
    var val = $ids.attr('value');
    var val_array = [];
    if (val) {
        val_array = val.split(',');
    }

    var fields = $(document).find('input.select_field');
    for (var i = 0, f; f = fields[i]; i++) {
        var item_id = $(f).attr('id').split('_').pop();
        var idx = $.inArray(item_id, val_array);

        if ($(this).is(':checked')) {
            if (idx == -1) {
                val_array.push(item_id);
            }
            $(f).prop('checked', true);
        } else {
            if (idx > -1) {
                val_array.splice(idx, 1);
            }
            $(f).prop('checked', false);
        }
    }
    $ids.attr('value', val_array.join(','));
    updateNumSelected($(this));
});

// handle re-attachment of JS function to pagination links when these are updated from the view
// (call this function from view after update of pagination)
$(document).on('rs.list_updated', function() {
    console.log('rs.list_updated called...');
    $('#pagination.search-enabled a').click(function(e) {
        sendPaginationSearch($(this));
        return false;
    });
    //$('form.pagination-pagesize-search-enabled-form').on('submit', function(e) {
    //$('#pagination_pagesize .input-group-button a').click(function(e) {
    $('.pagination-pagesize-bar .input-group-button a').click(function(e) {
        sendPaginationPagesizeSearch($(this));
        return false;
    });
    // do something to the select_all
    var some_checked = false;
    var fields = $(document).find('input.select_field');
    for (var i = 0, f; f = fields[i]; i++) {
        if ($(f).is(':checked')) {
            some_checked = true;
        }
    }
    $('input#select_all').prop('checked', some_checked);
    // bind ajax_search_form also - used in rapidi admin, service_accesses search
    $('.ajax_search_form input#search').livesearch({
        searchCallback: sendSearch,
        queryDelay: 250,
        innerText: "Search",
        minimumSearchLength: 2
    });
});

// handle remove of a deleted id from the selected ids (call after delete)
jQuery.fn.item_deleted = function() {
    //console.log('item_delete called on '+this.selector);
    var $this = $(this);
    if ($this.is(':checked')) {
        $this.click();
    }
};

// handle update of the list of data - for example after schedules stop_all or start_all (call from js view)
$(document).on('rs.reload_list', function() {
    //alert('we trigger the reload_list thing.');
    sendSearch();
});


$(document).on('ajax:error', '.panel_listen_ajax', function() {
    var $container = $(this).closest('.panel_container_ajax');
    var cid = $container.attr('id');
    $().show_flash_notice('some error happened - please reload the page and try again...');
});

Rs.ready(function() {
    $(document).on("click", ".ajax_pagination a", function (e) {
        console.log('ajax_pagination a clicked... (is this needed ?)')
        e.preventDefault();
        $.getScript(this.href);
        history.pushState(null, document.title, this.href);
        return false;
    });

    // handle update of file field after browse - for json upload/import
    $(document).on('change', '.ajax-upload-file', function (e) {
        var file_name = $(this).val();
        //console.log('we have file_name: '+file_name);
        if (file_name.indexOf('C:\\fakepath\\') == 0) {
            file_name = file_name.substr(12);  // string after pos 12
        }
        var prefix = $(this).attr("id").match(/(.*)json-upload/)[1];
        $('input#' + prefix + "uploadFile").prop('value', file_name);
    });

    $('input.select2-enabled').selectify();
});

$.fn.selectify = function(options) {
    console.log('selectify called...')
    return this.each(function() {
        var $this = $(this);
        var placeholder = $this.attr('data-select2-placeholder');
        var repo_url = $this.attr('data-select2-repository-url');
        var id_prop = $this.attr('data-select2-value-property');
        var text_prop = $this.attr('data-select2-search-property');
        var container_css_class = $this.attr('data-select2-container-css-class');
        var dropdown_css_class = $this.attr('data-select2-dropdown-css-class');
        console.log('selectify called - repo url: '+repo_url)

        $this.select2({
            dropdownAutoWidth : true,
            placeholder: placeholder,
            minimumInputLength: 0,
            ajax: {
                url: repo_url,
                dataType: 'json',
                //delay: 250,
                data: function(term, page) {
                    return {
                        search: term
                    };
                },
                results: function(data, page) {
                    results = [];
                    for (var i=0; i< data.length; i++) {
                        results.push({
                            id: data[i][id_prop],
                            text: data[i][text_prop]
                        });
                    }
                    return {results: results};
                }
            },
            initSelection: function(element, callback) {
                var val = element.val();
                console.log('initSelection: '+val)
                var jval = {};
                try {
                    jval = JSON.parse(val);
                } catch(err) {
                }
                console.log('initSelection2: '+val+' - val[id_prop]: '+jval[id_prop])
                if (val !== '') {
                    if (jval[id_prop] && jval[text_prop]) {
                        //alert('initSelection2: '+val+' - jval[id_prop]: '+jval[id_prop]+' - jval[text_prop]: '+jval[text_prop]);
                        element.attr('value', jval[id_prop]);
                        return callback({id: jval[id_prop], text: jval[text_prop]});
                    } else {
                        return callback({id: val, text: val});
                    }
                }
            },
            allowClear: true,
            containerCssClass: container_css_class,
            dropdownCssClass: dropdown_css_class
        }).on('select2-clearing', function() {
            var self = this;
            $(document).trigger({
                type: 'rs.select2-cleared',
                input: self
            });
        });
    });
};
