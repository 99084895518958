# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

jQuery(document).on 'turbolinks:load', ->
  $('.zone_member_edit_button').click (event)->
    event.preventDefault()
    $tr = $(this).closest('tr')
    $tr.find('.zone_member_show_mode').hide()
    $tr.find('.zone_member_edit_mode').show()

  $('.cancel_edit_zone_member_button').click (event)->
    event.preventDefault()
    $tr = $(this).closest('tr')
    $tr.find('.zone_member_show_mode').show()
    $tr.find('.zone_member_edit_mode').hide()

  #  $('.cancel_access_edit').click (e) ->
  #    e.preventDefault()
  #    $tr = $(this).closest('tr')
  #    data = {access:{}}
  #    data.authenticity_token = $("meta[name='csrf-token']").attr("content")
  #    data.cancel = true
  #    $.ajax
  #      url: $tr.attr('data-update-url'),
  #      type: 'put',
  #      dataType: 'script',
  #      data: data


  $('.update_zone_member_button').click (e)->
    e.preventDefault()
    $tr = $(this).closest('tr')
    update_url = $tr.attr('data-update-url')
    console.log('data update url: '+update_url)
    data = {zone_member:{}}
    data.authenticity_token = $("meta[name='csrf-token']").attr("content")
    data.save = true
    #$tr.find('input').each ->
    #  $this = $(this)
    #  console.log('got input field: '+$this.attr('name'))
    #  if $this.attr('type') == 'checkbox'
    #    data.organization_access[$this.attr('name')] = $this.prop('checked')
    #  else
    #    data.organization_access[$this.attr('name')] = $this.val()
    data.zone_member['countrycode'] = $tr.find('select.countrycode').val()
    data.zone_member['statecode'] = $tr.find('select.statecode').val()
    data.zone_member['postalcode'] = $tr.find('input.postalcode').val()
    #$tr.find('select').each ->
    #  $this = $(this)
    #  data.zone_member[$this.attr('name')] = $this.val()
    console.log('update - before sending data: '+JSON.stringify(data))
    $.ajax
      url: update_url,
      type: 'put',
      dataType: 'json',
      data: data,
      error: (jqXHR, textStatus, errorThrown) ->
        console.log('got error answer: '+textStatus)
        #$('body').append "AJAX Error: #{textStatus}"
      success: (response, textStatus, jqXHR) ->
        console.log('got positive answer: '+JSON.stringify(response))
        $tr.find('.zone_member_show_mode.show_countrycode').html(response['countryname'])
        $tr.find('.zone_member_show_mode.show_statecode').html(response['statename'])
        $tr.find('.zone_member_show_mode.show_postalcode').html(response['postalcode'])
        $tr.find('.zone_member_show_mode').show()
        $tr.find('.zone_member_edit_mode').hide()

