# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

jQuery(document).on 'turbolinks:load', ->
  # get contact details when changing contact
  $('#order_contact_id').change ->
    contact_id = $(this).val()
    contact_url = $(this).attr('data-contact-url')+'/'+contact_id
    console.log('Contact id changed: '+contact_id)
    console.log('Contact url: '+contact_url)
    # find states
    states = $('.country_states_container').attr('data_states')
    callback = (response) ->
      console.log('got contact: '+JSON.stringify(response))
      $('#order_phone').val(response['phone'])
      $('#order_email').val(response['email'])
      # billing address
      $('#order_firstname').val(response['firstname'])
      $('#order_lastname').val(response['lastname'])
      $('#order_companyname').val(response['companyname'])
      $('#order_street').val(response['street'])
      $('#order_postalcode').val(response['postalcode'])
      $('#order_city').val(response['city'])
      $('#order_countrycode').val(response['countrycode'])
      country = $('#order_countrycode :selected').attr("value")
      console.log('country selected 2: '+country)
      options = $(states).filter("optgroup[label='#{country}']").html()
      console.log('options 2: '+options)
      if options
        $('#order_statecode').html(options).parent().show()
      else
        $('#order_statecode').empty().parent().hide()
      $('#order_statecode').val(response['statecode'])
      # shipping address
      $('#order_shipping_firstname').val(response['firstname'])
      $('#order_shipping_lastname').val(response['lastname'])
      $('#order_shipping_companyname').val(response['companyname'])
      $('#order_shipping_street').val(response['street'])
      $('#order_shipping_postalcode').val(response['postalcode'])
      $('#order_shipping_city').val(response['city'])
      $('#order_shipping_countrycode').val(response['countrycode'])
      shipping_country = $('#order_shipping_countrycode :selected').attr("value")
      console.log('shipping_country2 selected: '+shipping_country)
      shipping_options = $(states).filter("optgroup[label='#{shipping_country}']").html()
      console.log('shipping_options2: '+shipping_options)
      if shipping_options
        $('#order_shipping_statecode').html(shipping_options).parent().show()
      else
        $('#order_shipping_statecode').empty().parent().hide()
      $('#order_shipping_statecode').val(response['statecode'])
    $.get contact_url, {}, callback, 'json'
