_.extend(window.Rs, {
    Models: {},
    Collections: {},
    Views: {
        Calculators: {},
        Images: {},
        Order: {},
        Cart: {},
        Zones: {},
        Payment: {},
        Promotions: {},
        Stock: {},
        Tables: {}
    }
})