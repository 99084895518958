var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "      class=\"negative count-on-hand-display\"\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "      class=\"count-on-hand-display\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            class=\"fullwidth negative\"\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            class=\"fullwidth\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"stock-location-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stockLocationName") || (depth0 != null ? lookupProperty(depth0,"stockLocationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stockLocationName","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":53}}}) : helper)))
    + "</td>\n<td class=\"align-center\">\n    <input id=\"backorderable-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":35}}}) : helper)))
    + "\"\n           name=\"backorderable\"\n           type=\"checkbox\"\n           value=\"backorderable\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"backorderable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":55}}})) != null ? stack1 : "")
    + "\n    >\n</td>\n<td class=\"align-center\">\n  <span\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"negative") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "  >"
    + alias4(((helper = (helper = lookupProperty(helpers,"count_on_hand") || (depth0 != null ? lookupProperty(depth0,"count_on_hand") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count_on_hand","hash":{},"data":data,"loc":{"start":{"line":17,"column":3},"end":{"line":17,"column":20}}}) : helper)))
    + "</span>\n</td>\n<td>\n    <form>\n        <input\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"negative") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "            name=\"count_on_hand\"\n            type=\"number\"\n            value=\"0\">\n    </form>\n</td>\n<td class=\"actions\">\n    <a class=\"submit fi fi-check icon_link with-tip no-text\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"saveTitle") || (depth0 != null ? lookupProperty(depth0,"saveTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"saveTitle","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":81}}}) : helper)))
    + "\" data-action=\"save\" href=\"#\"></a>\n    <a class=\"cancel fi fi-x icon_link with-tip no-text\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelTitle") || (depth0 != null ? lookupProperty(depth0,"cancelTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelTitle","hash":{},"data":data,"loc":{"start":{"line":34,"column":64},"end":{"line":34,"column":79}}}) : helper)))
    + "\" data-action=\"cancel\" href=\"#\"></a>\n</td>\n";
},"useData":true});