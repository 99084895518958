// Cookie Compliancy BEGIN
function GetCookie(name) {
    var arg=name+"=";
    var alen=arg.length;
    var clen=document.cookie.length;
    var i=0;
    while (i<clen) {
        var j=i+alen;
        if (document.cookie.substring(i,j)==arg)
            return "here";
        i=document.cookie.indexOf(" ",i)+1;
        if (i==0) break;
    }
    return null;
}
function testFirstCookie(){
    var offset = new Date().getTimezoneOffset();
    if ((offset >= -180) && (offset <= 0)) { // European time zones
        var cookie_name = $("#myCookieConsent").attr('data-consent-cookie-name');
        console.log('Cookie-consent - we are in europe - check cookie: '+cookie_name);
        var visit=GetCookie(cookie_name);
        if (visit==null){
            console.log('Cookie-consent - we are in europe - show cookie accept');
            $("#myCookieConsent").fadeIn(400);	// Show warning
        } else {
            console.log('Cookie-consent - we are in europe - already accepted');
            // Already accepted
        }
    }
}
Rs.ready(function() {
    $("#cookieButton").click(function(){
        console.log('Cookie-consent - we are in europe - cookies accepted');
        var expire=new Date();
        expire=new Date(expire.getTime()+7776000000);
        var cookie_name = $("#myCookieConsent").attr('data-consent-cookie-name');
        document.cookie=cookie_name+"=here; expires="+expire+";path=/";
        $("#myCookieConsent").hide(400);
    });
    testFirstCookie();
});
// Cookie Compliancy END