setup_payment_method_fields = ->
  selected_payment_method = $('#payment_method_payment_type').val()
  if selected_payment_method == 'Stripeapi'
    $('.payment_method_stripeapi_fields input').prop("disabled",false)
    $('.payment_method_stripeapi_fields').show()
  else
    $('.payment_method_stripeapi_fields input').prop("disabled",true)
    $('.payment_method_stripeapi_fields').hide()
  if selected_payment_method == 'Stellar'
    $('.payment_method_stellar_fields input').prop("disabled",false)
    $('.payment_method_stellar_fields').show()
  else
    $('.payment_method_stellar_fields input').prop("disabled",true)
    $('.payment_method_stellar_fields').hide()

jQuery(document).on 'turbolinks:load', ->
  setup_payment_method_fields()

  # show correct payment method details when changing payment method (select field)
  $('#payment_method_payment_type').change ->
    setup_payment_method_fields()
