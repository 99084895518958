format_amount = (nbr) ->
  if nbr != null
    amt = ''
    curr_sign = $('#checkout_orderlines').data('currency_symbol')
    curr_fmt = $('#checkout_orderlines').data('currency_format')
    if curr_fmt
      if typeof nbr == 'string'
        nbr = Number(nbr)
      #console.log(typeof nbr)
      amt = curr_fmt.replace('%u',curr_sign).replace('%n',nbr.toFixed(2))
    amt

calculate_line_amount = (line_container) ->
  amount = 0.00
  quantity = line_container.find('.order_line_quantity input').val()
  price = line_container.attr('data-price')
  discount = line_container.attr('data-discount')
  $amount = line_container.find('.order_line_amount')
  console.log('calculate_line_amount - quantity: '+quantity.toString())
  amount = quantity * price
  if discount > 0
    amount = amount * (100 - discount) / 100
  console.log('calculate_line_amount - amount: '+amount.toString())
  line_container.attr('data-amount', amount.toString())
  $amount.html(format_amount(amount))
  amount

calculate_orderlines_total = ->
  console.log('calculate_orderlines_total')
  total_amount = 0.00
  $('#checkout_orderlines').find('.orderline').each ->
    $tr = $(this)
    amount = $tr.attr('data-amount')
    console.log('calculate_orderlines_total - got amount: '+amount.toString())
    total_amount += Number(amount)
  console.log('calculate_orderlines_total - total_amount: '+total_amount.toString())
  $('#orderlines_total_amount').attr('data-orderlines_total_amount', total_amount.toString())
  $('#orderlines_total_amount').html(format_amount(total_amount))

recalculate_all_lines = ->
  console.log('recalculate_all_lines')
  total_amount = 0.00
  $('#checkout_orderlines').find('.orderline').each ->
    $tr = $(this)
    amount = calculate_line_amount($tr)
    total_amount += amount
  console.log('recalculate_all_lines - total_amount: '+total_amount.toString())
  $('.orderlines_total_amount').val(format_amount(total_amount))
  $('.orderlines_total_amount').data('orderlines_total_amount',total_amount.toString())

calculate_shipping = ->
  shipping_amount = 0.00
  console.log('calculate_shipping')
  selected_shipping = ''
  total_shipping_amount = 0.00
  $('#checkout_shipping').find('input[name^="order[shipments][selected_shipping_"]:checked').each ->
    selected_shipping = $(this)
    console.log(selected_shipping)
    amount = parseFloat(selected_shipping.data('rate_amount'))
    total_shipping_amount += amount
  # selected_shipping = $('#checkout_shipping input[name="order[shipments][selected_shipping_rate_id]"]:checked')
  if selected_shipping.length > 0
    shipping_amount = total_shipping_amount
  shipping_amount

# return true if any orderline quantities have changed
orderline_quantities_changed = ->
  console.log('orderline_quantities_changed')
  any_changed = false
  $('#checkout_orderlines').find('.orderline').each ->
    $tr = $(this)
    console.log('orderline_quantities_changed - original: '+$tr.data('original').toString()+", current: "+$tr.find('.order_line_quantity input').val().toString())
    if Number($tr.data('original')) != Number($tr.find('.order_line_quantity input').val())
      any_changed = true
  console.log('orderline_quantities_changed - any_changed: '+any_changed.toString())
  any_changed

calculate_total_amount_incl_shipping = ->
  console.log('calculate_total_amount_incl_shipping')
  total_amount = $('#orderlines_total_amount').attr('data-orderlines_total_amount')
  # update total with shipping amount
  total_amount_with_shipping = Number(total_amount) + Number(calculate_shipping())
  $('#checkout_order_total_amount').attr('data-amount', total_amount_with_shipping.toString())
  $('#checkout_order_total_amount').html(format_amount(total_amount_with_shipping))

shipping_country_state_zip_changed = ->
  console.log('shipping_country_state_zip_changed')
  changed = false
  #$checkout_pickup_stock_location = $('#checkout_pickup_stock_location')
  #if $checkout_pickup_stock_location.length > 0
  #  pickup_location_id = $('#checkout_pickup_stock_location input[name="order[pickup_stock_location_id]"]:checked').val()
  #  if pickup_location_id.toString() != $checkout_pickup_stock_location.attr('data-original')
  #    changed = true
  #    console.log('shipping_country_state_zip_pickup_changed - pickup_location has changed')
  $shipping_postalcode = $('#order_shipping_postalcode')
  if $shipping_postalcode.length > 0 and $shipping_postalcode.val() != $shipping_postalcode.attr('data-original')
    changed = true
  $shipping_countrycode = $('#order_shipping_countrycode')
  if $shipping_countrycode.length > 0 and $shipping_countrycode.val() != $shipping_countrycode.attr('data-original')
    changed = true
  $shipping_statecode = $('#order_shipping_statecode')
  if $shipping_statecode.length > 0 and $shipping_statecode.val() != $shipping_statecode.attr('data-original')
    changed = true
  console.log('shipping_country_state_zip_changed - statecode: '+$shipping_statecode.val())
  console.log('shipping_country_state_zip_changed - changed: '+changed.toString())
  changed

have_selected_all_shipping_rates = ->
  res = false
  all_selected = true
  $('.order_shipment').each ->
    $shipment = $(this)
    rate_name = $shipment.attr('data-rate_name')
    if not $('input[name="'+rate_name+'"]:checked').val()?
      console.log('have_selected_all_shipping_rates - have shipment without selected rate: '+rate_name)
      all_selected = false
  if $('.order_shipment')? and all_selected
    res = true
  res

have_selected_payment_method = ->
  res = false
  if $('input[name="order[payment_method_id]"]:checked', '#checkout_payment').val()?
    res = true
    console.log('have_selected_payment_method -> yes.')
  res

# update order_state after change of orderlines
set_new_order_state_after_change = ->
  console.log('set_new_order_state_after_change')
  $order_state = $('#order_state')
  if $order_state.length == 0
    return
  if orderline_quantities_changed()
    $order_state.val('cart')
  else if shipping_country_state_zip_changed()
    $order_state.val('address')
  else if not have_selected_all_shipping_rates()
    $order_state.val('delivery')
  else if not have_selected_payment_method()
    $order_state.val('payment')
  else
    $order_state.val('confirm')
  #$order_state.val($order_state.attr('data-original'))
  console.log('set_new_order_state_after_change - new value: '+$order_state.val())

# TODO - not used anymore
calculate_total_xxx = ->
  console.log('calculate_total')
  total_amount = 0.00
  $('#checkout_orderlines').find('.orderline').each ->
    $tr = $(this)
    amount = $tr.data('amount')
    total_amount += Number(amount)
  console.log('calculate_total - total_amount: '+total_amount.toString())
  $('.orderlines_total_amount').data('orderlines_total_amount',total_amount)
  $('#order_total').val(total_amount)
  $('.orderlines_total_amount').html(format_amount(total_amount))
  # update also total with shipping amount
  total_amount_with_shipping = total_amount + Number(calculate_shipping())
  $('#checkout_order_total_amount').data('amount', total_amount_with_shipping)
  $('#checkout_order_total_amount').html(format_amount(total_amount_with_shipping))


# TODO - not used anymore
update_order_line = (line_container) ->
  # we need to update the server with new quantity
  res = false
  update_order_line_url = line_container.find('form').attr('action')
  data = {}
  data.authenticity_token = line_container.find("input[name='authenticity_token']").val()
  data.quantity = line_container.find(".order_line_quantity input").val()
  console.log('before calling to update order line - with url: '+update_order_line_url)
  hide_shipping_and_payment_details()
  #$.ajax
  #  url: update_order_line_url,
  #  type: 'put',
  #  dataType: 'json',
  #  async: false,
  #  timeout: 20000,
  #  data: data,
  #  error: (jqXHR, textStatus, errorThrown) ->
  #    console.log('got error answer: '+textStatus)
  #  success: (response, textStatus, jqXHR) ->
  #    console.log('got answer: '+JSON.stringify(response))
  #    line_container.data('price', response['price'])
  #    line_container.find(".order_line_price").html(format_amount(response['price'].toString()))
  #    line_container.data('discount', response['discount'])
  #    line_container.find(".order_line_discount").html(format_amount(response['discount']))
  #    line_container.data('amount', response['amount'])
  #    line_container.find(".order_line_amount").html(format_amount(response['amount']))

# send all order lines to server
update_all_order_lines = (event) ->
  # we need to update the server with new quantity
  res = false
  $checkout_form = $('#order_checkout_update_form')
  update_order_line_url = $checkout_form.attr('action')
  data = {}
  data.authenticity_token = $checkout_form.find("input[name='authenticity_token']").val()
  data.orderlines_data = gather_order_lines_data()
  # TODO - not needed anymore, remove ?needs to have the order param set, per the params settings.
  data.order = {}
  # get state. If it is 'complete', abort order lines update and return error to user.
  data.order["state"] = $('#order_state').val()
  console.log('before calling to update all order lines - with url: '+update_order_line_url)
  $.ajax
    url: update_order_line_url,
    dataType: 'json',
    type: 'put',
    async: false,
    timeout: 20000,
    data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      event.preventDefault()
      console.log('got error answer: '+textStatus)
      # TODO - show error
    success: (response, textStatus, jqXHR) ->
      console.log('got answer: '+JSON.stringify(response))
#      line_container.data('price', response['price'])
#      line_container.find(".order_line_price").html(format_amount(response['price'].toString()))
#    line_container.data('discount', response['discount'])
#    line_container.find(".order_line_discount").html(format_amount(response['discount']))
#    line_container.data('amount', response['amount'])
#    line_container.find(".order_line_amount").html(format_amount(response['amount']))

delete_order_line = (line_container) ->
  # we need to update the server and delete the line
  res = false
  delete_order_line_url = line_container.find('form').attr('action')
  data = {}
  data.authenticity_token = line_container.find("input[name='authenticity_token']").val()
  console.log('before calling to delete order line - with url: '+delete_order_line_url)
  #line_container.slideUp().remove()
  #gather_order_lines_data()
  # TODO - we should probably make a full remote request and redraw the whole order to be able
  # TODO -  to show shipping options etc. if we have address information already !
  # TODO -  at least if we have already order.client_id !
  $.ajax
    url: delete_order_line_url,
    type: 'delete',
    dataType: 'json',
    async: false,
    timeout: 20000,
    data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      console.log('got error answer: '+textStatus)
      # TODO - show error message - translated
      Rs.show_flash('alert', errorThrown.toString())
    success: (response, textStatus, jqXHR) ->
      console.log('got answer: '+JSON.stringify(response))
      line_container.slideUp().remove()
      # update order.state
      $('#order_state').html(response['order_state'])
  res = true
  res

gather_order_lines_data = ->
  # Gather the order lines data, specifically id and quantity for each one, 
  # so the order model can go through it and update the quantities for the lines.
  data = []
  $('.orderline').each ->
    $order_line = $(this)
    id = parseInt($order_line.find('.edit_order_line').attr('id').match(/\d+$/)[0])
    quantity = parseInt($order_line.find('.order_line_quantity input').val())
    console.log( 'Order line id: ' + id + ' - quantity: ' + quantity)
    data.push({'orderline_id': id, 'orderline_quantity' : quantity })
    #data[id] = quantity

  data_string = JSON.stringify(data)
  #$('#orderlines_data').val(data_string)
  #console.log($('#orderlines_data'))
  data_string



validate_all_data = ->
  res = true
  console.log('Validate all data for order submit')
  $('.edit_order').find('input.mandatory').each ->
    $this = $(this)
    val = $this.val()
    if val == null || val == "" || val == " "
      res = false
      $this.addClass('error')
      console.log('have error on mandatory field: ')
      console.log($this)
    else
      $this.removeClass('error')
  # check terms_of_service
  if $('#order_terms_of_service').length > 0
    console.log('checking on terms_of_service...')
    if $('#order_terms_of_service').prop('checked')
      $('#order_terms_of_service').removeClass('error')
    else
      $('#order_terms_of_service').addClass('error')
      res = false
  # check recaptcha if it is there
  if $('.g-recaptcha').length > 0
    console.log('checking on recaptcha...')
    try
      response = grecaptcha.getResponse()
      if response.length == 0
        console.log('recaptcha not verified')
        $('.g-recaptcha').find('div').first().addClass('error')
        res = false
      else
        console.log('recaptcha is verified - ok')
        $('.g-recaptcha').find('div').first().removeClass('error')
    catch error
      console.log('recaptcha check, got error: '+error.toString())
  # validate that preference is selected
  if $('#contact_contact_preference_id').length > 0
    if $('#contact_contact_preference_id').val() > 0
      console.log('contact preference is selected')
      $('#contact_contact_preference_id').removeClass('error')
    else
      console.log('contact preference not selected')
      res = false
      $('#contact_contact_preference_id').addClass('error')
  res

stripe_get_current_info = ->
  #if $('#stripe_info').length > 0
  #  return $('#stripe_info')
  #return null
  payment_method_id = $('input[name="order[payment_method_id]"]:checked', '#checkout_payment').val()
  console.log('Have Payment Method id: '+payment_method_id)
  res = null
  $('.payment_types').find('.payment_method').each ->
    $this = $(this)
    if $this.attr('data-id') == payment_method_id
      if $this.attr('data-type') == 'Stripeapi'
        res = $this.find('#stripe_info')
        console.log('yes - it is stripe - return res: ')
        console.log(res)
  res

stripe = null
stripe_card_element = null
stripe_setup_card = ->
  $stripe_info = stripe_get_current_info()
  if $stripe_info == null
    console.log('stripe_setup_card - stripe is not used !')
    return
  console.log('stripe_setup_card - it is stripe')
  if not stripe?
    console.log('stripe not yet initialized - setup stripe')
    current_locale = $('#current_locale').val()
    try
      stripe = Stripe($stripe_info.attr('data_public_key'), { betas: ['payment_intent_beta_3'] })
    catch error
      console.log('got error initializing Stripe - error: '+error.toString())
      #stripe_load_needed = true
      setTimeout((->
        console.log('before retry stripe load.')
        setup_payment_method()
      ), 800)
    if stripe
      elements = stripe.elements( locale: current_locale)
      stripe_card_element = elements.create('card')
      stripe_card_element.mount('#card-element')
      #stripe_load_needed = null
      console.log('all good for stripe')

setup_payment_method = ->
  #stripe_setup_card()
  console.log('setup_payment_method')
  selected_payment_method_id = $('input[name="order[payment_method_id]"]:checked').val()
  console.log('we got payment method id: '+selected_payment_method_id)
  $('.payment_types').find('.payment_method').each ->
    $this = $(this)
    if $this.attr('data-id') == selected_payment_method_id
      stripe_setup_card($this)
      $this.show()
    else
      $this.hide()

setup_shipping_method = (elem) ->
  console.log('setup_shipping_method: ')
  console.log(elem)
  $order_shipment = elem.closest('.order_shipment')
  console.log($order_shipment)
  selected_shipping_rate_id = elem.val()
  console.log('we got shipping rate id: '+selected_shipping_rate_id)
  $order_shipment.find('.shipping_rate_description').each ->
    $this = $(this)
    if $this.attr('data-id') == selected_shipping_rate_id
      $this.show()
    else
      $this.hide()

stripe_need_client_secret = (stripe_info, total_amount_cent) ->
  if stripe_info.attr('data_client_secret') != ''
    console.log('we already have client_secret - check amount')
    if stripe_info.attr('data_total_amount_cent') == total_amount_cent.toString()
      console.log('we already have client_secret and total_amount is the same - just return')
      return false
  true

stripe_get_client_secret = (stripe_info, total_amount_cent, name, club_id, email) ->
  # only get it if its not already set (or amount different)
  if not stripe_need_client_secret(stripe_info, total_amount_cent)
    return true

  # we need to get new client_secret
  res = false
  payment_intent_url = stripe_info.attr('data_payment_intent_url')
  data = {}
  data.public_key = stripe_info.attr('data_public_key')
  data.authenticity_token = $("meta[name='csrf-token']").attr("content")
  data.total_amount_cent = total_amount_cent.toString()
  data.name = name
  data.email = email
  console.log('before calling to get new payment_intent - with url: '+payment_intent_url)
  $.ajax
    url: payment_intent_url,
    type: 'post',
    dataType: 'json',
    async: false,
    timeout: 20000,
    data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      console.log('got error answer: '+textStatus)
      #$('body').append "AJAX Error: #{textStatus}"
    success: (response, textStatus, jqXHR) ->
      console.log('got payment_intent: '+JSON.stringify(response))
      stripe_info.attr('data_client_secret', response['client_secret'])
      stripe_info.attr('data_total_amount_cent', total_amount_cent.toString())
      res = true
  res

hide_show_shipping_payment_submit = ->
  # hides the shipping and payment fields upon an order line change, forcing the user 
  # to submit the data so it can recalculate the shipment options and payment options correctly.
  # based on order state !
  if $('#order_state').length == 0
    return
  order_state = $('#order_state').val()
  console.log('hide_show_shipping_payment_submit - found order_state: '+order_state.toString())
  switch order_state
    when 'address', 'cart'
      $('#checkout_shipping').hide()
      $('#checkout_submit_order_update').show()
      $('#checkout_total_with_shipping').hide()
      $('#checkout_payment').hide()
      $('#submit_order_button').prop("disabled",true)
    when 'delivery'
      $('#checkout_shipping').show()
      $('#checkout_submit_order_update').hide()
      $('#checkout_total_with_shipping').show()
      $('#checkout_payment').hide()
      $('#submit_order_button').prop("disabled",true)
    when 'payment'
      $('#checkout_shipping').show()
      $('#checkout_submit_order_update').hide()
      $('#checkout_total_with_shipping').show()
      $('#checkout_payment').show()
      $('#submit_order_button').prop("disabled",true)
    when 'confirm'
      $('#checkout_shipping').show()
      $('#checkout_submit_order_update').hide()
      $('#checkout_total_with_shipping').show()
      $('#checkout_payment').show()
      $('#submit_order_button').prop("disabled",false)
    else
      $('#submit_order_button').prop("disabled",true)

hide_show_shipping_address_section = ->
  if $('#different_shipping_address').is(":checked")
    $('#shipping_address_section').slideDown()
  else
    $('#shipping_address_section').slideUp()


jQuery(document).on 'turbolinks:load', ->

  if $('#checkout_orderlines').length == 0
    return
  calculate_orderlines_total()
  calculate_total_amount_incl_shipping()
  hide_show_shipping_address_section()
  hide_show_shipping_payment_submit()
  setup_payment_method()

  $('#member_email').on 'invalid', (event) -> 
    # on an Invalid event from HTML5's email validation, set a custom translated message that makes sense.
    console.log(this.validity)     

    if event.target.validity.typeMismatch || event.target.validity.valueMissing
      event.target.setCustomValidity($(this).data("email-error"))
    else
      console.log("No errors")
  
  $('#member_email').change -> 
    # When you change the input, remove the custom error message
    this.setCustomValidity("")         



  # calculate line_amount and total when quantity changes (includes product-count + - changes
  $(".order_line_quantity input[name='order_line[quantity]']").change ->
    qty = $(this).val()
    console.log('Quantity changed: '+qty)
    $order_line = $(this).closest(".orderline")
    calculate_line_amount($order_line)
    calculate_orderlines_total()
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()
    #calculate_total_amount_incl_shipping()

  $('#checkout_orderlines a.delete_line').click (event)->
    event.preventDefault()
    console.log('delete line: ')
    $order_line = $(this).closest(".orderline")
    delete_order_line($order_line)
    hide_show_shipping_payment_submit()
    calculate_orderlines_total()
    #calculate_total_amount_incl_shipping()

  # TODO - not used anymore
  #$('#shipping_copy_billing_info').click (event)->
  #  event.preventDefault()
  #  console.log('copy billing info')
  #  $('#order_shipping_firstname').val($('#order_firstname').val())
  #  $('#order_shipping_lastname').val($('#order_lastname').val())
  #  $('#order_shipping_companyname').val($('#order_companyname').val())
  #  $('#order_shipping_street').val($('#order_street').val())
  #  $('#order_shipping_postalcode').val($('#order_postalcode').val())
  #  $('#order_shipping_city').val($('#order_city').val())
  #  $('#order_shipping_countrycode').val($('#order_countrycode').val())
  #  $('#order_shipping_countrycode').trigger('change');
  #  $('#order_shipping_statecode').val($('#order_statecode').val())

  $('#different_shipping_address').change ->
    console.log('different_shipping_address changed')
    hide_show_shipping_address_section()

  # keep shipping address the same as billing address as long as different_shipping_address is not checked
  $('#order_firstname').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_firstname').val($('#order_firstname').val())
  $('#order_lastname').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_lastname').val($('#order_lastname').val())
  $('#order_companyname').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_companyname').val($('#order_companyname').val())
  $('#order_street').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_street').val($('#order_street').val())
  $('#order_postalcode').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_postalcode').val($('#order_postalcode').val())
      set_new_order_state_after_change()
      hide_show_shipping_payment_submit()
  $('#order_city').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_city').val($('#order_city').val())
  $('#order_countrycode').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_countrycode').val($('#order_countrycode').val()).trigger('change')
      set_new_order_state_after_change()
      hide_show_shipping_payment_submit()
  $('#order_statecode').change ->
    if not $('#different_shipping_address').is(":checked")
      $('#order_shipping_statecode').val($('#order_statecode').val())
      set_new_order_state_after_change()
      hide_show_shipping_payment_submit()


  # continue shopping button
  $('#continue_shopping_button').click (event) ->
    console.log('continue_shopping_button')
    if orderline_quantities_changed()
      console.log('continue_shopping_button - orderlines changed - send them in')
      update_all_order_lines(event)
    # continue with default behavior (switch to product index)

  # identify yourself button
  $('#order_checkout_identify_yourself_button').click (event) ->
    console.log('identify_yourself_button')
    if orderline_quantities_changed()
      console.log('identify_yourself_button - orderlines changed - send them in')
      update_all_order_lines(event)
    # TODO - give error if email address is not entered (correctly)
    # continue with default behavior (send email address)

  # show correct shipping method description when changing shipping method (radio button)
  #$('#checkout_pickup_stock_location input[name="order[pickup_stock_location_id]"').change ->
  #  console.log('selected new pickup location')
  #  hide_show_shipping_address_section()
  #  set_new_order_state_after_change()
  #  hide_show_shipping_payment_submit()


  # check if shipping details are changed -> need to send in new data
  $('#order_shipping_postalcode').change ->
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()
  $('#order_shipping_countrycode').change ->
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()
  $('#order_shipping_statecode').change ->
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()

  # show correct shipping method description when changing shipping method (radio button)
  $('input[name^="order[shipments][selected_shipping_"').change ->
    console.log('selected new shipment rate')
    setup_shipping_method($(this))
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()
    calculate_total_amount_incl_shipping()

  # show correct payment method details when changing payment method (radio button)
  $('input[name="order[payment_method_id]"]').change ->
    payment_method_id = $('input[name="order[payment_method_id]"]:checked').val()
    console.log('Payment Method changed: '+payment_method_id)
    setup_payment_method()
    set_new_order_state_after_change()
    hide_show_shipping_payment_submit()

  # before leaving the window, we check if we have unsaved changes and ask to save (this only detects closing the window ! - not selecting another menu item, like 'wines')
  $(window).on 'beforeunload', ->
    if orderline_quantities_changed()
      return 'Are you sure you want to leave?'


  # submit order update
  $('#checkout_submit_order_update').click (event) ->
    console.log('submit order update button')
    # TODO - check that mandatory fields have been filled in
    #$('#order_confirmed').val('false')      # TODO - dont know why this is sometimes true ?
    # TODO continue to send in changes (should switch to remote=>true)


  # submit order
  $('#submit_order_button').click (event) ->
    console.log('submit order button')

    #payment_reference = $('#event_registration_payment_reference').val()
    #if payment_reference and payment_reference != ''
    #  console.log('submit event registration - we already have payment_reference: '+payment_reference)
    #  return true
    #recalculate_all_lines()
    event.preventDefault()

    $('#validation-errors').hide()
    $('#payment-errors').hide()
    $('#card-element').removeClass('error')
    $this = $(this)
    if not validate_all_data()
      $('#validation-errors').show()
      console.log('submit order - data error - dont send')
      return false       # dont propagate event (=preventDefault)
    # check that we have shipping selected

    # check that we have order_state = payment or confirm
    if $('#order_state').val() != 'confirm' and $('#order_state').val() != 'payment'
      $('#validation-errors').show()
      console.log('submit order - order_state is not "confirm" or "payment" - dont send')
      return false       # dont propagate event (=preventDefault)

    # only take payment if amount > 50 cents
    #total_amount = $('#orderlines_total_amount').data('orderlines_total_amount')
    total_amount = $('#checkout_order_total_amount').attr('data-amount')
    total_amount_cent = Number(total_amount)*100

    $stripe_info = stripe_get_current_info()
    #alert('after  stripe_get_current_info')
    if $stripe_info != null and total_amount_cent > 50
      console.log('submit order - it is stripe')
      cardHolderName = $('#order_firstname').val()+' '+$('#order_lastname').val()
      console.log('submit order - it is stripe - got name: '+cardHolderName)
      console.log('submit order - it is stripe - got total_amount: '+total_amount+' and total_amount_cent: '+total_amount_cent.toString())
      email = $('#order_email').val()
      contact_id = $('#order_contact_id').val()
      # disable button before calling stripe
      $this.attr('disabled', true)
      #alert('before stripe_get_client_secret')
      if not stripe_get_client_secret($stripe_info, total_amount_cent, cardHolderName, contact_id, email)
        $('#validation-errors').show()
        #event.preventDefault()
        console.log('submit order - could not get client_secret - dont send')
        return false
      # handle stripe payment
      client_secret = $stripe_info.attr('data_client_secret')
      console.log('Stripe_capture_payment, client_secret: '+client_secret)
      console.log('Stripe_capture_payment, stripe: '+stripe.toString())

      stripe.handleCardPayment(
        client_secret, stripe_card_element, {
          source_data: {
            owner: {name: cardHolderName}
          }
        }
      ).then (response) ->
        if response.error
          # Display error.message in your UI.
          console.log('Stripe_capture_payment, result error: '+JSON.stringify(response))
          #event.preventDefault()
          $('#payment-errors').html(response['error']['message']).show()
          $('#card-element').addClass('error')
          $this.attr('disabled', false)
          console.log('submit order - error in capture payment - dont send')
          return false
        else
          # The payment has succeeded. Display a success message.
          console.log('Stripe_capture_payment, result ok: '+JSON.stringify(response))
          $('#order_payment_reference').val(response['paymentIntent']['id'])
          $('#order_payment_amount').val(total_amount)
          $('#order_confirmed').val('true')
          console.log('submit order - got payment - now send data to server - reference: '+response['paymentIntent']['id'])
          #$('#orderlines_total_amount').attr('disabled', false)   # enable field to transfer the data
          #$('#order_lines').find('.amount_field').attr('disabled', false)   # enable amount_field to transfer the data
          # $('#new_event_registration').submit()
          #alert('got stripe payment - before submit order')
          $('.edit_order').submit()    # submit order form
    else
      # not stripe - submit order
      $('#order_lines').find('.amount_field').attr('disabled', false)   # enable amount_field to transfer the data


      $('#order_payment_amount').val(total_amount)      
      $('#orderlines_total_amount').attr('disabled', false)   # enable field to transfer the data
      $('#checkout_order_total_amount').attr('disabled', false)   # enable field to transfer the data
     
      $('#order_confirmed').val('true')
      $('.edit_order').submit()    # submit order form


    console.log('submit order - we are at the end...')



