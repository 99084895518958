# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/


jQuery(document).on 'turbolinks:load', ->
  thumbnails = $("#product-images ul.thumbnails")
  $("#main-image").data("selectedThumb", $("#main-image img").attr("src"))
  if !thumbnails.find("li.selected").length
    thumbnails.find("li").eq(0).addClass("selected")

  thumbnails.find("a").click (event)->
    event.preventDefault()
    console.log('click a thumbnail link.')
    $("#main-image").data("selectedThumb", $(event.currentTarget).attr("data-main-img-src"))
    $("#main-image").data("selectedThumbId", $(event.currentTarget).parent().attr("id"))
    thumbnails.find("li").removeClass("selected")
    $(event.currentTarget).parent("li").addClass("selected")
    $("#main-image a").attr("href",$(event.currentTarget).attr("href"))
    false

  thumbnails.find("li").mouseenter (event)->
    console.log('mouseover li thumbnail.')
    $("#main-image img").attr("src",$(event.currentTarget).find("a").attr("data-main-img-src"))
    # next line also updates the link (only use if mouseleave is commented out)
    $("#main-image a").attr("href",$(event.currentTarget).find("a").attr("href"))

  thumbnails.find("li").mouseleave (event)->
    #console.log('mouseleave li thumbnail.')
    #$("#main-image img").attr("src", $("#main-image").data("selectedThumb"))

  product_count = $(".product-count")
  product_count.find(".product-count-minus a").click (event)->
    event.preventDefault()
    console.log('click minus.')
    parent = $(this).closest(".product-count")
    count_min = parent.data("min_quantity")
    #console.log(count_min)
    count_field = parent.find(".product-count-field input").first()
    console.log(count_field)
    count = Number(count_field.val())
    if count > Number(count_min)
      count -= 1
      count_field.val(count.toString())
      count_field.trigger('change')

  product_count.find(".product-count-plus a").click (event)->
    event.preventDefault()
    #console.log('click plus.')
    parent = $(this).closest(".product-count")
    count_max = parent.data("max_quantity")
    available_stock = parent.data("available")
    #console.log(count_max)
    count_field = parent.find(".product-count-field input").first()
    #console.log(count_field)
    count = Number(count_field.val())
    if count < Number(count_max) and count < Number(available_stock)
      count += 1
      count_field.val(count.toString())
      count_field.trigger('change')
    else if count >= Number(available_stock)
      Rs.show_flash('alert','There is not enough of the product')
