/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpacker and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import jquery from 'jquery-ui/dist/jquery.js'
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// import ledger stuff
require("@ledgerhq/hw-transport-u2f");
require("@ledgerhq/hw-transport-webusb");
require("@ledgerhq/hw-app-str");
// import stellar stuff
require("@ledgerhq/hw-app-str");
import TransportU2F from "@ledgerhq/hw-transport-u2f"
import TransportWebUSB from "@ledgerhq/hw-transport-webusb"
import AppStr from "@ledgerhq/hw-app-str"

//require("@foundation-sites/js/foundation.core.js");
require("foundation-sites/dist/js/foundation.js");

//require('select2/dist/js/select2.js');        //  select2/dist/js/select2.min.js   or full.js     - for versions 4.0
//require('select2/dist/js/select2.full.js');        //  select2/dist/js/select2.min.js   or full.js     - for versions 4.0
//require('select2/src/js/select2/compat/initSelection.js');
//require('select2/dist/css/select2.css');
require('select2/select2.js');
require('select2/select2.css');

// include Trix editor
//let Trix = require('trix/dist/trix.js');
require('trix/dist/trix.js');
import Trix from "trix/dist/trix"
require('trix/dist/trix.css')
//require("@rails/actiontext")
// we can get access to Trix.config here if needed
//Trix.config.blockAttributes.heading1.tagName = "h3";

// make $ alias available
window.jQuery = $;
window.$ = $;
window._ = require('underscore');
require('../javascript/rs/rs.js');         // Rs (Rapid Solucions) namespace - instead of Spree

require('../javascript/accesses.js');
require('../javascript/livesearch');
require('../javascript/notifications.js');
require('../javascript/action_bar.coffee');
require('../javascript/filter_action_bar');
require('../javascript/trix_editor.coffee');
require('../javascript/contacts.coffee');
require('../javascript/orders.coffee');
require('../javascript/order_lines.coffee');
require('../javascript/products.coffee');
require('../javascript/checkout.coffee');
require('../javascript/zone_members.coffee');
require('../javascript/payment_methods.coffee');
require('../javascript/country_state.coffee');
require('../javascript/calculator.coffee');
require('../javascript/stellar.coffee');
require('../javascript/wineries.coffee');
require('../javascript/cookie_consent');

// JS using Backbone.js - related to stock_management
require('handlebars');         // handlebars templating
require('../javascript/backbone.js');
require('../javascript/rs/namespaces.js');
require('../javascript/rs/backbone-overrides.js');
require('../javascript/rs/models/stock_item.js');
require('../javascript/rs/views/stock/add_stock_item.js');
require('../javascript/rs/views/stock/edit_stock_item_row.js');
require('../javascript/rs/stock_management.js');

// WebAuthn
require('../javascript/credentials.js');


console.log('Hello World from Webpacker');

// stylesheets - all through application.scss
require('../stylesheets/application.scss');

//const fonts = require.context('../fonts', true);
//require("../fonts/OpenSans-Regular.ttf");
//import OpenSansRegular from "../fonts/OpenSans-Regular.ttf"

const fonts = require.context('../fonts', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true);
// const imagePath = (name) => images(name, true)

// get foundation javascript up and running
// load foundation after turbolinks has loaded !
$(document).on('turbolinks:load', function() {
    $(function(){
        $(document).foundation();
        console.log('Hello World from Webpacker and jquery (and foundation ;-) - version: '+ jQuery.fn.jquery);
    });
    setTimeout(function(){
        $('#flash-notice').trigger('close');
    },10000);
    setTimeout(function(){
        $('#flash-alert').trigger('close');
    },15000);
});
//$(function() {
//    $(document).foundation();
//    console.log('Hello World from Webpacker and jquery - version: '+ jQuery.fn.jquery);
//});
