function setup_stock_items() {
    $('.js-edit-stock-item').each(function() {
        var $el = $(this);
        var model = new Rs.Models.StockItem($el.data('stock-item'));
        var trackInventory = $el.data('track-inventory');
        var canEdit = $el.data('can-edit')
        new Rs.Views.Stock.EditStockItemRow({
            el: $el,
            stockLocationName: $el.data('stock-location-name'),
            stockLocationId: $el.data("stock-item").stock_location_id,
            productSku: $el.data("product-sku"),
            saveTitle: $el.data("save_title"),
            cancelTitle: $el.data("cancel_title"),
            model: model
        });

        if (trackInventory === false) {
            $el.find('input').attr({
                disabled: true,
                class: 'with-tip',
                title: '"Track inventory" option disabled for this product'
            });
        }

        if (canEdit == false) {
            $el.find('input').attr({
                disabled: true,
                class: 'with-tip',
                title: 'You do not have permission to manage stock'
            });
        }
    });

    $('.js-add-stock-item').each(function() {
        var $el = $(this)
        var model = new Rs.Models.StockItem({
            product_id: $el.data('product-id')
        });
        new Rs.Views.Stock.AddStockItem({
            el: $el,
            model: model
        });
    });
};

Rs.ready(function() {
    setup_stock_items();
});

// handle re-attachment of JS function to stock_items when these are updated from the view
// (call this function from view after update of pagination)
$(document).on('rs.stock_items_updated', function() {
    console.log('rs.stock_items_updated called...');
    setup_stock_items();
});
