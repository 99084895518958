validate_all_data = ->
  res = true
  console.log('Validate all data for contact signup')
  $('#new_contact').find('input.mandatory').each ->
    $this = $(this)
    val = $this.val()
    if val == null || val == "" || val == " "
      res = false
      $this.addClass('error')
    else
      $this.removeClass('error')
  # check terms_of_service
  if $('#contact_terms_of_service').length > 0
    if $('#contact_terms_of_service').prop('checked')
      $('#contact_terms_of_service').removeClass('error')
    else
      $('#contact_terms_of_service').addClass('error')
      res = false
  # check recaptcha if it is there
  if $('.g-recaptcha').length > 0
    console.log('checking on recaptcha...')
    try
      response = grecaptcha.getResponse()
      if response.length == 0
        console.log('recaptcha not verified')
        $('.g-recaptcha').find('div').first().addClass('error')
        res = false
      else
        console.log('recaptcha is verified - ok')
        $('.g-recaptcha').find('div').first().removeClass('error')
    catch error
      console.log('recaptcha check, got error: '+error.toString())
  # validate that club is selected
  if $('#contact_contact_preference_id').length > 0
    if $('#contact_contact_preference_id').val() > 0
      console.log('contact preference is selected')
      $('#contact_contact_preference_id').removeClass('error')
    else
      console.log('contact preference not selected')
      res = false
      $('#contact_contact_preference_id').addClass('error')
  res


stripe_get_current_info = ->
  if $('#stripe_info').length > 0
    return $('#stripe_info')
  return null
#  payment_method_id = $('input[name="event_registration[payment_method_id]"]:checked', '#new_event_registration').val()
#  console.log('Have Payment Method id: '+payment_method_id)
#  res = null
#  $('.payment_types').find('.payment_method').each ->
#    $this = $(this)
#    if $this.attr('data_id') == payment_method_id
#      if $this.attr('data_type') == 'Stripeapi'
#        res = $this.find('#stripe_info')
#        console.log('yes - it is stripe - return res= '+res.toString())
#  res

#stripe_load_needed = null
stripe = null
stripe_card_element = null
stripe_setup_card = ->
  $stripe_info = stripe_get_current_info()
  if $stripe_info == null
    console.log('stripe_setup_card - stripe is not used !')
    return
  console.log('stripe_setup_card - it is stripe')
  if not stripe?
    console.log('stripe not yet initialized - setup stripe')
    current_locale = $('#current_locale').val()
    try
      stripe = Stripe($stripe_info.attr('data_public_key'), { betas: ['payment_intent_beta_3'] })
    catch error
      console.log('got error initializing Stripe - error: '+error.toString())
      #stripe_load_needed = true
      setTimeout((->
        console.log('before retry stripe load.')
        setup_payment_method()
      ), 800)
    if stripe
      elements = stripe.elements( locale: current_locale)
      stripe_card_element = elements.create('card')
      stripe_card_element.mount('#card-element')
      #stripe_load_needed = null
      console.log('all good for stripe')

setup_payment_method = ->
  stripe_setup_card()
  #selected_payment_method_id = $('input[name="event_registration[payment_method_id]"]:checked', '#new_event_registration').val()
  #$('.payment_types').find('.payment_method').each ->
  #  $this = $(this)
  #  if $this.attr('data_id') == selected_payment_method_id
  #    stripe_setup_card($this)
  #    $this.show()
  #  else
  #    $this.hide()

stripe_need_client_secret = (stripe_info, total_amount_cent) ->
  if stripe_info.attr('data_client_secret') != ''
    console.log('we already have client_secret - check amount')
    if stripe_info.attr('data_total_amount_cent') == total_amount_cent.toString()
      console.log('we already have client_secret and total_amount is the same - just return')
      return false
  true

stripe_get_client_secret = (stripe_info, total_amount_cent, name, club_id, email) ->
# only get it if its not already set (or amount different)
  if not stripe_need_client_secret(stripe_info, total_amount_cent)
    return true

  # we need to get new client_secret
  res = false
  event_payment_intent_url = stripe_info.attr('data_payment_intent_url')
  data = {}
  data.public_key = stripe_info.attr('data_public_key')
  data.authenticity_token = $("meta[name='csrf-token']").attr("content")
  data.total_amount_cent = total_amount_cent.toString()
  data.name = name
  data.email = email
  console.log('before calling to get new payment_intent - with url: '+event_payment_intent_url)
  $.ajax
    url: event_payment_intent_url,
    type: 'post',
    dataType: 'json',
    async: false,
    timeout: 20000,
    data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      console.log('got error answer: '+textStatus)
      #$('body').append "AJAX Error: #{textStatus}"
    success: (response, textStatus, jqXHR) ->
      console.log('got payment_intent: '+JSON.stringify(response))
      stripe_info.attr('data_client_secret', response['client_secret'])
      stripe_info.attr('data_total_amount_cent', total_amount_cent.toString())
      res = true
  res

# NOT USED
stripe_capture_payment = (stripe_info, card_holder_name, total_amount) ->
  console.log('Stripe_capture_payment, with name: '+card_holder_name+', and amount: '+total_amount)
  total_amount_number = Number(total_amount)*100
  console.log('Stripe_capture_payment, total_amount_number: '+total_amount_number)
  client_secret = stripe_info.attr('data_client_secret')
  console.log('Stripe_capture_payment, client_secret: '+client_secret)
  console.log('Stripe_capture_payment, stripe: '+stripe.toString())
  res = null

  stripe.handleCardPayment(
    client_secret, stripe_card_element, {
      source_data: {
        owner: {name: card_holder_name}
      }
    }
  ).then (response) ->
    if response.error
      # Display error.message in your UI.
      console.log('Stripe_captura_payment, result error: '+response.error)
    else
      # The payment has succeeded. Display a success message.
      console.log('Stripe_captura_payment, result ok: '+JSON.stringify(response))
      $('#event_registration_payment_reference').val(response.paymentIntent)


jQuery(document).on 'turbolinks:load', ->
  #states = $('#contact_statecode').html()
  # initial setup state select
  #country = $('#contact_countrycode :selected').attr("value")
  #options = $(states).filter("optgroup[label='#{country}']").html()
  #console.log('initial state options: '+options)
  #if options
  #  $('#contact_statecode').html(options).parent().show()
  #else
  #  $('#contact_statecode').empty().parent().hide()
  # handle when changes
  #$('#contact_countrycode').change ->
  #  country = $('#contact_countrycode :selected').attr("value")
  #  console.log('country selected: '+country)
  #  #if not country
  #  #  country = 'AU'
  #  #escaped_country = country.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1')
  #  options = $(states).filter("optgroup[label='#{country}']").html()
  #  console.log('state options: '+options)
  #  if options
  #    $('#contact_statecode').html(options).parent().show()
  #  else
  #    $('#contact_statecode').empty().parent().hide()

  # after load, ensure payment method is initialized to selected
  #initial_payment_method_id = $('#event_registration_payment_method_id').val()
  #initial_payment_method_id = $('input[name="event_registration[payment_method_id]"]:checked', '#new_event_registration').val()
  setup_payment_method()

  $('#new_contact input.mandatory').change ->
    $this = $(this)
    val = $this.val()
    if !(val == null || val == "" || val == " ")
      $this.removeClass('error')

  # show correct payment method details when changing payment method (select field)
  $('#event_registration_payment_method_id').change ->
    payment_method_id = $(this).val()
    console.log('Payment Method changed: '+payment_method_id)
    setup_payment_method()

  # show correct payment method details when changing payment method (radio button)
  $('input[name="event_registration[payment_method_id]"]').change ->
    payment_method_id = $('input[name="event_registration[payment_method_id]"]:checked', '#new_event_registration').val()
    console.log('Payment Method changed: '+payment_method_id)
    setup_payment_method()

  # submit registration
  $('#submit_contact_button').click (event) ->
    console.log('submit contact signup')

    #payment_reference = $('#event_registration_payment_reference').val()
    #if payment_reference and payment_reference != ''
    #  console.log('submit event registration - we already have payment_reference: '+payment_reference)
    #  return true
    #calculate_all_lines()
    event.preventDefault()

    $('#validation-errors').hide()
    $('#payment-errors').hide()
    $('#card-element').removeClass('error')
    $this = $(this)
    if not validate_all_data()
      #$('#validation-errors').show()
      error_text = $('#validation-errors').attr('data-error-text')
      $().show_flash_alert(error_text)
      console.log('submit contact signup - data error - dont send')
      return false       # dont propagate event (=preventDefault)

    $stripe_info = stripe_get_current_info()
    if $stripe_info != null
      console.log('submit contact signup - it is stripe')
      cardHolderName = $('#contact_firstname').val()+' '+$('#contact_lastname').val()
      console.log('submit contact signup - it is stripe - got name: '+cardHolderName)
      email = $('#contact_email').val()
      # disable button before calling stripe
      $this.attr('disabled', true)
      #if not stripe_get_client_secret($stripe_info, total_amount_cent, cardHolderName, club_id, email)
      #  $('#validation-errors').show()
      #  #event.preventDefault()
      #  console.log('submit contact signup - could not get client_secret - dont send')
      #  return false
      # handle stripe payment
      client_secret = $stripe_info.attr('data_client_secret')
      console.log('Stripe_confirm_card_setup, client_secret: '+client_secret)
      console.log('Stripe_confirm_card_setup, stripe: '+stripe.toString())

      stripe.confirmCardSetup(
        client_secret,
        {
          payment_method: {
            card: stripe_card_element,
            billing_details: {
              name: cardHolderName,
            },
          },
        }
      ).then (response) ->
        if response.error
          # Display error.message in your UI.
          console.log('Stripe_confirm_card_setup, result error: '+JSON.stringify(response))
          #event.preventDefault()
          $('#payment-errors').html(response['error']['message']).show()
          $('#card-element').addClass('error')
          $this.attr('disabled', false)
          console.log('submit contact signup - error in capture payment - dont send')
          return false
        else
          # The payment has succeeded. Display a success message.
          console.log('Stripe_confirm_card_setup, result ok: '+JSON.stringify(response))
          $('#contact_stripe_payment_method').val(response['setupIntent']['payment_method'])
          console.log('submit contact signup - got card setup - now send data to server')
          $this.closest('form').submit()
    else
      console.log('submit contact signup - stripe not used - just send data to server')
      $this.closest('form').submit()
