# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

order_line_edit = (elem)->
  $tr = elem.closest('tr')
  $tr.find('.order_line_show_mode').hide()
  $tr.find('.order_line_edit_mode').show()

order_line_cancel = (elem)->
  $tr = elem.closest('tr')
  $tr.find('.order_line_show_mode').show()
  $tr.find('.order_line_edit_mode').hide()

order_line_update = (elem)->
  $tr = elem.closest('tr')
  update_url = $tr.attr('data-update-url')
  console.log('data update url: '+update_url)
  data = {order_line:{}}
  data.authenticity_token = $("meta[name='csrf-token']").attr("content")
  data.save = true
  $tr.find('input').each ->
    $this = $(this)
    if ($this.attr('type') == 'checkbox')
      data.order_line[$this.attr('name')] = $this.prop('checked')
    else
      data.order_line[$this.attr('name')] = $this.val()
  $tr.find('select').each ->
    $this = $(this)
    data.order_line[$this.attr('name')] = $this.val()
  console.log('update: '+data.order_line.toString())
  $.ajax
    url: update_url,
    type: 'put',
    dataType: 'script',
    data: data,
  #$.ajax
  #  url: update_url,
  #  type: 'put',
  #  dataType: 'json',
  #  data: data,
  #  error: (jqXHR, textStatus, errorThrown) ->
  #    console.log('got error answer: '+textStatus)
  #    $().show_flash_error(textStatus)
  #    #$('body').append "AJAX Error: #{textStatus}"
  #  success: (response, textStatus, jqXHR) ->
  #    console.log('got positive answer: '+JSON.stringify(response))
  #    $tr.find('.order_line_show_mode.product_name').html(response['product_name'])
  #    $tr.find('.order_line_show_mode.description').html(response['description'])
  #    $tr.find('.order_line_show_mode.quantity').html(response['quantity'])
  #    $tr.find('.order_line_show_mode.price').html(response['price'])
  #    $tr.find('.order_line_show_mode.discount').html(response['discount'])
  #    $tr.find('.order_line_show_mode.amount').html(response['amount'])
  #    $tr.find('.order_line_show_mode').show()
  #    $tr.find('.order_line_edit_mode').hide()

order_line_product_changed = (elem)->
  $tr = elem.closest('tr')
  product_id = elem.val()
  qty = $tr.find('.order_line_edit_mode #quantity').val()
  product_price_url = elem.attr('data-product-price-url')+'?product_id='+product_id+'&quantity='+qty
  console.log('Product id changed: '+product_id)
  console.log('Product_price url: '+product_price_url)
  console.log('Quantity is: '+qty)
  callback = (response) ->
    console.log('got product: '+JSON.stringify(response))
    $tr.find('#description').val(response['description'])
    $tr.find('#discount').val(response['discount'])
    $tr.find('#price').val(response['price'])
  $.get product_price_url, {}, callback, 'json'


jQuery(document).on 'turbolinks:load', ->
  $('.order_line_edit_button').click (event)->
    console.log('clicked on edit')
    event.preventDefault()
    order_line_edit($(this))

  $('.cancel_order_line_button').click (event)->
    event.preventDefault()
    order_line_cancel($(this))

  $('.update_order_line_button').click (event)->
    event.preventDefault()
    order_line_update($(this))

  # get product details when changing product
  $('.order_line_product_xxxx').change ->
    product_id = $(this).val()
    product_url = $(this).attr('data-product-url')+'/'+product_id
    console.log('Product id changed: '+product_id)
    console.log('Product url: '+product_url)
    $tr = $(this).closest('tr')
    callback = (response) ->
      console.log('got product: '+JSON.stringify(response))
      $tr.find('#description').val(response['name'])
      $tr.find('#price').val(response['master_price'])
    $.get product_url, {}, callback, 'json'

  # get product details when changing product
  $('.order_line_product').change ->
    order_line_product_changed($(this))

jQuery(document).on 'rs.order_lines_updated', ->
  console.log('rs.order_lines_update called...')
  $('.order_line_edit_button').click (event)->
    event.preventDefault()
    order_line_edit($(this))

  $('.cancel_order_line_button').click (event)->
    event.preventDefault()
    order_line_cancel($(this))

  $('.update_order_line_button').click (event)->
    event.preventDefault()
    order_line_update($(this))

  # get product details when changing product
  $('.order_line_product').change ->
    order_line_product_changed($(this))
