import * as WebAuthnJSON from "@github/webauthn-json"
import { supported } from "@github/webauthn-json"

//import { showMessage } from "messenger";

// gets the Browser and OS, so we can avoid the user being confused by the lack of functionality outside of Windows for Firefox 88 .
function detectBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
        return 'IE';
    } else {
        return 'Unknown';
    }
} 

function detectOS() { 
    if (navigator.userAgent.indexOf("Windows NT 10") != -1 ) {
        return 'Windows 10';
    } else if(navigator.userAgent.indexOf("Mac") != -1 ) {
        return 'Mac';
    } else if(navigator.userAgent.indexOf("X11") != -1) {
        return 'UNIX';
    } else if(navigator.userAgent.indexOf("Linux") != -1 ){
        return 'Linux';
    } else {
        return 'Unknown';
    }
}


function getCSRFToken() {
    var CSRFSelector = document.querySelector('meta[name="csrf-token"]')
    if (CSRFSelector) {
        return CSRFSelector.getAttribute("content")
    } else {
        return null
    }
}

function callback(url, data, dataType) {

    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function(xhr, status, error) { 

        },
        error: function(xhr, status, error) {
            if (xhr.status < 500) {
                console.log(error);
                $().show_flash_alert(error);
            /*
            } else if (xhr.responseText.length < 500) {
                // If the responseText is small, return it as the error for the user to see, otherwise return a generic error
                $().show_flash_alert(xhr.responseText);
            */
            } else {
                //$().show_flash_alert("There was a problem during the Security Key registration process - Please try again");
                $().show_flash_alert(Rs.translations.credential_registration_error);
            }
        },
        dataType: dataType
    });

}



function create(callbackUrl, credentialOptions, data) {
    WebAuthnJSON.create({ "publicKey": credentialOptions }).then(function(credential) {
        callback(callbackUrl, {"publicKeyCredentials": credential,  "nickname": data["nickname"], "user_verification": data["user_verification"] }, "script");
    }).catch(function(error) {
        if (error.name == "InvalidStateError"){
            // Log the exact error to the console
            console.log(error);
            $().show_flash_alert(Rs.translations.credential_key_reuse_error);
            //$().show_flash_alert("You've already used this security key - please try with a different key.");           
        } else {
            console.log(error);
            $().show_flash_alert(Rs.translations.credential_registration_error);
            //$().show_flash_alert("There was a problem registering your security key - please try again");            
        }
    });
}

// check if security keys are supported by this browser and OS - give error message and return true if ok, else false
function checkSecurityKeysSupported(passwordless, login) {
    if ( !supported() ) {
        console.log('WebAuthn browser check (supported()) failed !');
        if (login) {
            //if ($('#enforce_security_key').val() == 'true') {
            //    $().show_flash_alert("Use of a Security Key during login is enforced for you, but this browser does not support it - please use a recent Firefox, Chrome, Edge or Safari Browser.");
            // } else if ($('#need_mfa_code').val() == 'true' ) {
            //     $().show_flash_alert("This browser does not support security keys - Please try with 2FA Code or use a recent Firefox, Chrome, Edge or Safari Browser.");
            //} else {
            //    $().show_flash_alert("You need to use a Security Key during login - this browser does not support security keys - Please use a recent Firefox, Chrome, Edge or Safari Browser.");
            //}

            //$().show_flash_alert("You need to use a Security Key during login - this browser does not support security keys - Please use a recent Firefox, Chrome, Edge or Safari Browser.");
            $().show_flash_alert(Rs.translations.credential_missing_login_error);
        } else {
            //$().show_flash_alert('This browser does not support the use of Security Keys - please use a recent Firefox, Chrome, Edge or Safari Browser.');
            $().show_flash_alert(Rs.translations.credential_unsupported_error);
        }
        return false;
    } else if (detectBrowser() == 'Firefox' && detectOS() != "Windows 10") {
        console.log('WebAuthn supported but have Firefox and not Windows10 !');
        if (passwordless) {
            //$().show_flash_alert('Firefox browser does not support the use of Security Keys as passwordless on this OS.');
            $().show_flash_alert(Rs.translations.credential_firefox_passwordless_error);
            return false;
        } else {
            //$().show_flash_notice('Please note: Firefox browser does not support the use of Security Keys as passwordless on this OS.');
            $().show_flash_notice(Rs.translations.credential_firefox_passwordless_error);
            return true;
        }
    } else {
        console.log('WebAuthn browser check (supported()) succeeded !');
    }
    return true;
}


function verifyAndSubmitSecondFactor() {
    console.log('verifySecondFactor');
    //// If user enters a Google Auth code and keys aren't enforced, submit instead of trying to verify Security Key as Second factor
    // if ($('#enforce_security_key').val() != 'true' && $('#mfa_code').val())  {
    //     $('#login_form').trigger('submit');
    // } else if ( checkSecurityKeysSupported(false, true) ) {
    if ( checkSecurityKeysSupported(false, true) ) {
        //// Use WebAuthn Get, with passwordless == false, as it is a 2FA request, after it has been interacted with by the user, send the data to the server
        var request_options = JSON.parse($('#publicKeyCredentials').attr('key_request_options'));
        webAuthnGet(request_options, false).then(function(credential_verified){
            if (credential_verified) {
                console.log("Got Security Key verified in browser, now login to server");
                $('#new_user').trigger('submit');
            };
        });
    };
    // } else {
    //     if ($('#enforce_security_key').val() != 'true' && $('#need_mfa_code').val() == 'true' ) {
    //         $('#mfa_code_field').show();
    //         $('#mfa_remember_me_field').show();
    //     }
    // };
}



//// login page - try to verify a Security Key (either as passwordless or second factor), handle fallback to password, 2FA etc.
async function webAuthnGet(credentialOptions, passwordless) {
    //// Hide input and label for password // Could probably use a unified Class that can be hidden and shown applied to all these fields.

    if (passwordless == "true") {
        // Passwordless login route

        $('#password_field').hide();
        // $('#mfa_code_field').hide();
        // $('#mfa_remember_me_field').hide();

        var credential_verification_data = await WebAuthnJSON.get({ "publicKey": credentialOptions }).then(function(credential) {

            $('#verified_passwordless').val(JSON.stringify(credential) );
            return true

        }).catch(function(error) {
            // If there was an error (cancelling out of the window counts as an error to WebAuthn), then re-show the password field again.
            $('#password_field').show();

            // if ($('#need_2FA_key').val() != 'true') {
            //     // if Second Factor keys are not an option, show the 2FA fields
            //     $('#mfa_code_field').show();
            //     $('#mfa_remember_me_field').show();
            // }
            //// Log the exact error to the console, and give a more human readable general error to the user.
            console.log(error);
            //$().show_flash_alert("There was a problem using your security key during the login process - Please use another login method");
            $().show_flash_alert(Rs.translations.credential_login_error);
            return false
        });
    } else {        
        //// Second Factor + Password login route
        var credential_verification_data = await WebAuthnJSON.get({ "publicKey": credentialOptions }).then(function(credential) {

            $('#verified_2FA_credential').val(JSON.stringify(credential) );
            return true

        }).catch(function(error) {
            //// Log the exact error to the console, and give a more human readable general error to the user.
            console.log(error);         

            $('#password_field').show();

            // // if mfa (google authenticator) enabled and security keys are not enforced:
            // if ($('#enforce_security_key').val() != 'true' && $('#need_mfa_code').val() == 'true' ) {
                
            //     $('#mfa_code_field').show();
            //     $('#mfa_remember_me_field').show();
            // }

            if ($('#enforce_security_key').val() == 'true') {
                // $().show_flash_alert("Use of a Security Key is enforced for you, but there was a problem verifying your security key during the login process - Please try again or contact your administrator.");
                $().show_flash_alert(Rs.translations.credential_required_login_error);
                return false
            // } else if ($('#need_mfa_code').val() == 'true' ) {
            //     $().show_flash_alert("There was a problem using your security key - Please try with 2FA Code or contact your administrator");
            } else {
                // // Bypasses the Second Factor verification (there is a server-side check to ensure it's valid, given the ease of an attacker modifying JS)
                // $().show_flash_alert(Rs.translations.security_key_bypass_active);
                return true
            }

            //$().show_flash_alert("You need to use a Security Key during login - Please try again or contact your administrator");
            //$().show_flash_alert(Rs.translations.credential_required_login_error);
        });
    }
    return credential_verification_data
}

async function getPublicKeyCredentials(url, user_verification) {
    // here make an async call to the server side, to the getCreateOptions function in the controller, and get back a JSON of the results
    
    var create_options = null;

    create_options = await $.ajax({
        type: "POST",
        url: url + "/getCreateOptions",
        data: { "user_verification": user_verification},
        success: function(data) {
            return data
        },
        error: function(xhr, status, error) {
            if (xhr.status < 500) {
                console.log(error);
                $().show_flash_alert(error);
            } else {
                console.log(error);
                //$().show_flash_alert("Sorry, something went wrong with the creation of your new security key - please contact support");
                $().show_flash_alert(Rs.translations.credential_registration_error);
            }
        },
        dataType: "json"
    });
    return create_options

}


$(document).ready(function() {
    // Test browser support if we're on a WebAuthn page (Security Keys page)
    if ( $('#new_credential').length > 0) {
        checkSecurityKeysSupported(false, false);
    }
});

// bind 2FA_login button to function
$(document).on('click', '#2FA_login', function(e) {
    e.preventDefault();
    verifyAndSubmitSecondFactor();
});

// handle click on login continue button to retrieve information on user
$(document).on('click', '#continue_button', function(e) {

    e.preventDefault();
    var login_url = $('#new_user').attr('action');
    var login_email = $(this).closest('form').find("input[name='user[email]']").val();
    $.ajax({
        type: "POST",
        url: login_url,
        data: { "user" : {"email" : login_email}  },
        success: function(xhr, status, error) {
            console.log("got login data - jubii");

            if ($('#publicKeyCredentials').attr('data_request_options') !== undefined && $('#passwordless').val() == 'true') {
                // we want passwordless
                if ( checkSecurityKeysSupported(true, true) ) {

                    var request_options = JSON.parse($('#publicKeyCredentials').attr('data_request_options'));
                    // retrieve WebAuthnGet results, initiate credential verification, and submit login form if successfull.
                    console.log(request_options);
                    webAuthnGet(request_options, "true").then(function (credential_verified) {
                        console.log(credential_verified);
                        if (credential_verified) {
                            $('#new_user').trigger('submit');
                        }
                    });
               } else {
                    $('#password_field').show();
                    // if ($('#enforce_security_key').val() != 'true' && $('#need_mfa_code').val() == 'true' && (!supported())) {
                    //     $('#mfa_code_field').show();
                    //     $('#mfa_remember_me_field').show();
                    // }
               }
            }
        },
        error: function(xhr, status, error) {
            console.log("got login error");
            //console.log(error);
            if (xhr.status < 500) {
                $().show_flash_alert(error);
            } else {
                //$().show_flash_alert("There was a problem during the login - please try again");
                $().show_flash_alert(Rs.translations.login_error);
            }
        },
        dataType: "script"
    });

});

// Credentials (Security Keys) page
$(document).on('click', '#new_credential input[type="submit"]', async function(e) {
    e.preventDefault();
    // If Webauthn is not supported or the user is running Firefox on a non-windows machine, throw an error
    if ( !supported() || (detectBrowser() == 'Firefox' &&  detectOS() != "Windows 10") ) {
        console.log('WebAuthn browser check failed !');
        //$().show_flash_alert('This browser does not support the use of Security Keys - please use a recent Chrome, Edge or Safari Browser (Firefox only supports passwordless in Windows 10, through Windows Hello).');
        $().show_flash_alert(Rs.translations.credential_unsupported_error);
    } else {
        var user_id = $(this).closest('form').attr('data-user-id');
        var user_name = $(this).closest('form').attr('data-user-name');

        var callback_url = $(this).closest('form').attr('action');

        var credential_nickname = $(this).closest('form').find("input[name='credential[nickname]']").val();
        var user_verification = $(this).closest('form').find("input[name='credential[user_verification]']").is(":checked"); ////

        // Use the new function in the Credentials Controller to get a JSON create_options object back
        var create_options = await getPublicKeyCredentials(callback_url, user_verification)
        //var create_options = JSON.parse($(this).closest('form').attr('data-create-options'));
        //callback_url = callback_url + "?nickname=" + credential_nickname
        //callback_url = callback_url + "&user_verification=" + user_verification

        var data = { "nickname": credential_nickname, "user_verification": user_verification }

        await create(callback_url, create_options, data);
    }
});

$(document).on('click', '.edit_credential_button', function(e) {
    e.preventDefault();

    var $div = $(this).closest('.credential');

    $div.find('.credential_show_form').hide();
    $div.find('.credential_edit_form').show();
    $div.find('.credential_show_actions').hide();
    $div.find('.credential_edit_actions').show();
});

$(document).on('click', '.cancel_edit_credential_button', function(e) {
    e.preventDefault();

    var $div = $(this).closest('.credential');

    $div.find('.credential_edit_form').hide();
    $div.find('.credential_show_form').show();
    $div.find('.credential_edit_actions').hide();
    $div.find('.credential_show_actions').show();
});

$(document).on('click', '.submit_edit_credential_button', function(e) {
    e.preventDefault();

    var $credential = $(this).closest('.credential');
    var data = {credential: {}}

    $credential.find('.credential_edit_form input').each(function() {
        var $this = $(this);
        data.credential[$this.attr('name')] = $this.val();
    });

    $.ajax({
        url: $credential.attr('data-update-credential-url'),
        type: 'put',
        dataType: 'script',
        data: data
    });

});