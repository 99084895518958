# handle proper selection of country and state codes
# used on 'contacts', 'zone_members', 'orders'

jQuery(document).on 'turbolinks:load', ->
  # hold on to one global 'states'

  # setup initial options
  $('.statecode').each ->
    $this = $(this)
    states = $this.html()
    $parent = $this.closest('.country_state_container')
    $parent.attr('data_states', states)
    # initial setup state select
    country = $parent.find('.countrycode :selected').attr("value")
    options = $(states).filter("optgroup[label='#{country}']").html()
    console.log('initial state options: '+options)
    if options
      $parent.find('.statecode').html(options)
      if $parent.find('.countrycode').is(':visible')
        $parent.find('.statecode').parent().show()
    else
      $parent.find('.statecode').empty().parent().hide()

  # handle when changes
  $('.countrycode').change ->
    $this = $(this)
    $parent = $this.closest('.country_state_container')
    country = $parent.find('.countrycode :selected').attr("value")
    console.log('country selected: '+country)
    states = $parent.attr('data_states')
    options = $(states).filter("optgroup[label='#{country}']").html()
    console.log('state options: '+options)
    if options
      $parent.find('.statecode').html(options)
      if $parent.find('.countrycode').is(':visible')
        $parent.find('.statecode').parent().show()
    else
      $parent.find('.statecode').empty().parent().hide()
    # trigger change on statecode so that we can detect that e.g. order.shipping_statecode has changed
    $parent.find('.statecode').trigger('change')