# import stuff for Ledger
#import "babel-polyfill";
import StellarSdk from "stellar-sdk"
import TransportU2F from "@ledgerhq/hw-transport-u2f"
import TransportWebUSB from "@ledgerhq/hw-transport-webusb"
import AppStr from "@ledgerhq/hw-app-str"

stellar_sdk = null
stellar_server = null
stellar_setup = ->
  if jQuery('#stellar_active').length == 0
    return
  console.log(StellarSdk)
  #stellar_sdk = require('stellar-sdk')
  if $('#stellar_active').attr('data-testnet') == '1'
    stellar_server = new StellarSdk.Server('https://horizon-testnet.stellar.org')
    console.log('using Stellar TestNet !')
  else
    stellar_server = new StellarSdk.Server('https://horizon.stellar.org')
  console.log(stellar_server)

  #if not stellar_server?
  #  console.log('stellar_server not yet initialized - setup stellar')
  #  try
  #    #stripe = Stripe($stripe_info.attr('data-public_key'), { betas: ['payment_intent_beta_3'] })
  #  catch error
  #    console.log('got error initializing Stripe - error: '+error.toString())
  #    #stripe_load_needed = true
  #   setTimeout((->
  #      console.log('before retry stripe load.')
  #    ), 800)

stellar_account_setup = ->
  # hide/show elements regarding wallet_type
  wallet_type = $('input[name="stellar_account[wallet_type]"]:checked').val()
  stellar_account_wallet_show(wallet_type)


# see more about KeyPair objects: https://stellar.github.io/js-stellar-sdk/Keypair.html
stellar_create_key = ->
  keypair = StellarSdk.Keypair.random();
  console.log('Secret key (seed): '+keypair.secret())
  console.log('Public key: '+keypair.publicKey())
  return keypair

stellar_testnet_friendbot_create_account = (publickey)->
  parm = encodeURIComponent(publickey)
  friendbot_url = "https://friendbot.stellar.org?addr="+parm
  callback = (response) ->
    console.log('got create account response: '+JSON.stringify(response))
    #$('#create_account_result').html(JSON.stringify(response))
    #const responseJSON = await response.json();
    console.log("SUCCESS! You have a new account :)\n")
    return 'Successfully funded TestNet Account'
  try
    $.get friendbot_url, {}, callback, 'json'
  catch error
    console.log('got error on Stellar friendbot fund account - error: '+error.toString())

stellar_account_balance = (publickey)->
  console.log("stellar_account_balance for: '" + publickey+"'.");
  #account = await stellar_server.loadAccount(keyPair.publicKey());
  $account_balance = document.getElementById('stellar_account_balance')
  #account = await stellar_server.loadAccount(publickey);
  #console.log("Balances for account: " + publickey);
  #balance_html = "<table><thead><th>Type</th><th>Balance</th></thead>"
  #account.balances.forEach (balance)->
  #  console.log("Type:", balance.asset_type, ", Balance:", balance.balance);
  #  balance_html += "<tr><td>"+balance.asset_type+"</td><td>"+balance.balance+"</td></tr>"
  #balance_html += "</table>"
  #$account_balance.innerHTML = balance_html
  stellar_server
    .loadAccount(publickey)
    .then (account)->
      balance_html = "<table><thead><th>Token</th><th>Balance</th></thead>"
      account.balances.forEach (balance)->
        console.log("Type:", balance.asset_type, ", Balance:", balance.balance);
        # In Stellar’s API, Lumens are referred to as the “native” type.
        # Other asset types have more detailed information.
        asset = '';
        if balance.asset_type == "native"
          asset = "Stellar Lumens (XLM)"
        else
          asset = balance.asset_code
          #asset = balance.asset_code + ":" + balance.asset_issuer
        balance_html += "<tr><td>"+asset+"</td><td>"+balance.balance+"</td></tr>"
        #balance_html += "<tr><td>"+balance.asset_type+"</td><td>"+balance.balance+"</td></tr>"
      balance_html += "</table>"
      $account_balance.innerHTML = balance_html
    .catch (error)->
      console.log('got error on loadAccount: '+error.toString())
      if error instanceof StellarSdk.NotFoundError
        $account_balance.innerHTML = "The account does not exist - please fund the account first !"
      else
        $account_balance.innerHTML = "Some error occured: "+error.toString()
      #throw new Error(error.toString())

stellar_get_network_passphrase = ->
  networkPW = StellarSdk.Networks.PUBLIC
  if $('#stellar_active').attr('data-testnet') == '1'
    networkPW = StellarSdk.Networks.TESTNET
  networkPW

stellar_get_transaction_fee_and_network = ->
  console.log('stellar_get_transaction_fee_and_network - at start')
  res = { fee: StellarSdk.BASE_FEE, networkPassphrase: stellar_get_network_passphrase() }
  console.log('stellar_get_transaction_fee_and_network - result:')
  console.log(res)
  res

stellar_sign_transaction = (signer_publickey, transaction, signer_wallet_type, signer_url, signer_privatekey = '') ->
  console.log('stellar_sign_transaction - with wallet_type: '+signer_wallet_type.toString())
  switch signer_wallet_type
    when '1'
      # send to our server for signing
      console.log('stellar_sign_transaction - send to server to sign.')
      transaction = await stellar_account_server_sign_transaction(signer_url, transaction)
    when '2'
      # use Ledger to sign
      console.log('stellar_sign_transaction - sign with Ledger.')
      transaction = await ledger_u2f_sign_transaction(signer_publickey, transaction)
    when '3'
      # use Trezor to sign
      console.log('stellar_sign_transaction - sign with Trezor.')

    else
      console.log('stellar_sign_transaction - no valid wallet_type - try sign with privatekey.')
      # check if we have a private key to sign
      signer_keypair = StellarSdk.Keypair.fromSecret(signer_privatekey.toString())
      transaction.sign(signer_keypair)
  transaction

stellar_submit_transaction = (transaction, mess) ->
  stellar_server.submitTransaction(transaction)
    .then (result)->
      console.log("Success! Results:", result)
      Rs.show_flash('notice',"The "+mess+" transaction was successfully processed.")
      return result
    .catch (error)->
      console.log('got error on submit transaction: '+error.toString())
      # maybe we need to try to re-submit the transaction - if we get unknown status back

# construct a Stellar Asset from the selected token id (ask server from issuer publickey if needed)
stellar_get_asset = (token_id) ->
  console.log('stellar_get_asset - at start')
  if token_id.toString() != '0'
    get_token_issuer_url_template = $('#stellar_active').attr('data-stellar_token_url')
    get_token_issuer_url = get_token_issuer_url_template.replace('TOKENID',token_id.toString())
    asset_info = await stellar_token_get_issuer(get_token_issuer_url)
    console.log('stellar_get_asset - got asset_info: ')
    console.log(asset_info)
    asset = new StellarSdk.Asset(asset_info['token_name'], asset_info['issuer_public_key'])
  else
    # Stellar native (XLM)
    asset = new StellarSdk.Asset.native()
  asset

stellar_make_payment = (source_publickey, destinationId, token_id, amount, memo, signer_wallet_type, signer_url)->
  # first create a transaction (including check/load accounts)
  console.log('stellar_make_payment - at start')
  asset = await stellar_get_asset(token_id)
  console.log('stellar_make_payment - got asset: ')
  console.log(asset)

  transaction = await stellar_create_payment_transaction(source_publickey, destinationId, amount, memo, asset)
  console.log('stellar_make_payment - got transaction:')
  console.log(transaction)
  # then sign the transaction
  signed_transaction = await stellar_sign_transaction(source_publickey, transaction, signer_wallet_type, signer_url)
  console.log('stellar_make_payment - got signed_transaction:')
  console.log(signed_transaction)
  # And finally, send it off to Stellar!
  await stellar_submit_transaction(signed_transaction, 'payment')

stellar_create_payment_transaction = (source_publickey, destination_publickey, amount, memo = '', asset = StellarSdk.Asset.native())->
  console.log('stellar_create_transaction')
  # First, check to make sure that the destination account exists.
  # You could skip this, but if the account does not exist, you will be charged
  # the transaction fee when the transaction fails.
  stellar_server
    .loadAccount(destination_publickey)
      # If the account is not found, surface a nicer error message for logging.
    .catch (error)->
      if error instanceof StellarSdk.NotFoundError
        Rs.show_flash_alert("The destination account does not exist!")
        throw new Error("The destination account does not exist!")
      else return error
    # If there was no error, load up-to-date information on your account.
    .then ->
      return stellar_server.loadAccount(source_publickey)
    .then (sourceAccount)->
      # Start building the transaction.
      transaction = new StellarSdk.TransactionBuilder(sourceAccount, stellar_get_transaction_fee_and_network())
        .addOperation(
          StellarSdk.Operation.payment({
            destination: destination_publickey,
            asset: asset,
            amount: amount.toString(),
          }),
        )
        # A memo allows you to add your own metadata to a transaction. It's
        # optional and does not affect how Stellar treats the transaction.
        .addMemo(StellarSdk.Memo.text(memo))
        # Wait a maximum of three minutes for the transaction
        .setTimeout(180)
        #.build()
      #console.log('have transaction:')
      #console.log(transaction)
      return transaction.build()
    .then (transaction)->
      console.log('have transaction:')
      console.log(transaction)
      return transaction
    .catch (error)->
      console.error("Something went wrong!", error);
      # If the result is unknown (no response body, timeout etc.) we simply resubmit
      # already built transaction:
      # server.submitTransaction(transaction);

stellar_create_token = (distributor_publickey, issuer_publickey, token_name, amount, distributor_wallet_type, distributor_signer_url, issuer_wallet_type, issuer_signer_url)->
  # first create a transaction (including check/load accounts)
  console.log('stellar_create_token - at start')
  asset = new StellarSdk.Asset(token_name, issuer_publickey)

  transaction = await stellar_create_trust_transaction(distributor_publickey, asset)
  console.log('stellar_create_token - got transaction:')
  console.log(transaction)
  # then sign the transaction
  signed_transaction = await stellar_sign_transaction(distributor_publickey, transaction, distributor_wallet_type, distributor_signer_url)
  # And finally, send it off to Stellar!
  await stellar_submit_transaction(signed_transaction, 'create_trust')

  # now send amount of new token to distributor account
  transaction2 = await stellar_create_payment_transaction(issuer_publickey, distributor_publickey, amount, 'token to distributor', asset)
  console.log('stellar_create_token - got payment transaction:')
  console.log(transaction2)
  # then sign the transaction
  signed_transaction2 = await stellar_sign_transaction(issuer_publickey, transaction2, issuer_wallet_type, issuer_signer_url)
  # And finally, send it off to Stellar!
  await stellar_submit_transaction(signed_transaction2, 'tokens to distributor')

# used from front contact - stellar_account to let client prepare to buy a token (client needs to sign transaction)
stellar_trust_token = (account_publickey, token_id, account_wallet_type, account_signer_url)->
  # first create a transaction (including check/load accounts)
  console.log('stellar_trust_token - at start')
  asset = await stellar_get_asset(token_id)
  console.log('stellar_make_payment - got asset: ')
  console.log(asset)
  transaction = await stellar_create_trust_transaction(account_publickey, asset)
  console.log('stellar_trust_token - got transaction:')
  console.log(transaction)
  # then sign the transaction
  signed_transaction = await stellar_sign_transaction(account_publickey, transaction, account_wallet_type, account_signer_url)
  # And finally, send it off to Stellar!
  stellar_submit_transaction(signed_transaction, 'create trust')

# ask central server stellar_account to sign transaction (it should have a private key for the account)
stellar_account_server_sign_transaction = (url, transaction, contact_token = null)->
  console.log('stellar_account_server_sign_transaction - url: '+url)
  data = {stellar_account:{}}
  data.authenticity_token = $("meta[name='csrf-token']").attr("content")
  if contact_token
    data.stellar_account['token'] = contact_token
  data.envelope_xdr = transaction.toEnvelope().toXDR('base64')
  #base64str = transaction.toEnvelope().toXDR().toString('base64')
  data.save = true
  console.log('sign transaction with data: '+data.toString())
  result = ''
  await $.ajax
    url: url,
    type: 'post',
    dataType: 'json',
    data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      console.log('got error answer: '+JSON.stringify(jqXHR))
      Rs.show_flash('alert', textStatus)
    success: (response, textStatus, jqXHR) ->
      console.log('got positive answer: '+JSON.stringify(response))
      #result = response['envelope_xdr']
      #tx = new StellarSdk.Transaction(response['envelope_xdr'])
      result = new StellarSdk.TransactionBuilder.fromXDR(response['envelope_xdr'], stellar_get_network_passphrase() )
  result

# ask central server stellar_token to get the issuer public_key
stellar_token_get_issuer = (url)->
  console.log('stellar_token_get_issuer - url: '+url)
  result = ''
  await $.ajax
    url: url,
    type: 'get',
    dataType: 'json',
    #data: data,
    error: (jqXHR, textStatus, errorThrown) ->
      console.log('got error answer: '+JSON.stringify(jqXHR))
      Rs.show_flash('alert', 'Trying to get Token Issuer Public Key - got error: '+jqXHR['responseText'])
    success: (response, textStatus, jqXHR) ->
      console.log('got positive answer: '+JSON.stringify(response))
      #result = response['issuer_public_key']
      result = response
  result

stellar_create_trust_transaction = (account_publickey, asset, trust_limit = "10000")->
  console.log('stellar_create_trust_transaction')
  # First, check to make sure that the distributor account exists.
  stellar_server
    .loadAccount(account_publickey)
      # If the account is not found, surface a nicer error message for logging.
    .catch (error)->
      if error instanceof StellarSdk.NotFoundError
        Rs.show_flash('alert', "The account does not exist!")
        throw new Error("The account does not exist!")
      else return error
    .then (account)->
      # Start building the transaction.
      transaction = new StellarSdk.TransactionBuilder(account, stellar_get_transaction_fee_and_network())
        .addOperation(
          StellarSdk.Operation.changeTrust({
            asset: asset,
            limit: trust_limit.toString(),
          }),
        )
        # Wait a maximum of three minutes for the transaction
        .setTimeout(180)
      return transaction.build()
    .then (transaction)->
      console.log('have transaction:')
      console.log(transaction)
      return transaction
    .catch (error)->
      console.error("stellar_create_trust_transaction - Something went wrong!", error);
      # If the result is unknown (no response body, timeout etc.) we simply resubmit
      # already built transaction:
      # server.submitTransaction(transaction);

stellarPaymentsSavePagingToken = (paging_token)->
  console.log('we got a new payment_token: '+paging_token.toString())

stellar_account_transactions = (publickey)->
  console.log("stellar_account_transactions for: '" + publickey+"'.");
  $account_transactions = document.getElementById('stellar_account_transactions')
  trans_html = "<table id=\"stellar_transactions_table\"><thead><th>Date</th><th>Amount</th><th>Asset</th><th>From</th></thead><tbody></tbody></table>"
  $account_transactions.innerHTML = trans_html
  payments = stellar_server.payments().forAccount(publickey)
  # If some payments have already been handled, start the results from the
  # last seen payment. (See below in `handlePayment` where it gets saved.)
  lastToken = '' # '1864213374963713'  #loadLastPagingToken();
  if lastToken
    payments.cursor(lastToken)

  # `stream` will send each recorded payment, one by one, then keep the
  # connection open and continue to send you new payments as they occur.
  #stellar_server.payments()
  #  .forAccount(publickey)
  payments.stream({
    onmessage: (payment)->
      console.log("stellar_account_transactions - in payment stream onmessage")
      # Record the paging token so we can start from here next time.
      stellarPaymentsSavePagingToken(payment.paging_token)
      # The payments stream includes both sent and received payments. We only
      # want to process received payments here.
      #if payment.to != publickey
      #  return
      # In Stellar’s API, Lumens are referred to as the “native” type. Other
      # asset types have more detailed information.
      asset = '';
      if payment.asset_type == "native" or payment.type == "create_account"
        asset = "lumens"
      else
        asset = payment.asset_code + ":" + payment.asset_issuer
      if payment.amount
        amount = payment.amount
      else
        amount = payment.starting_balance
      if payment.type == 'create_account'
        from = payment.source_account
      else
        from = payment.from
      console.log(payment.amount + " " + asset + " from " + payment.from)
      console.log(payment)
      trans_more_html = "<tr><td>" + payment.created_at + "</td><td>" + amount + "</td><td>" + asset + "</td><td>" + from + "</td></tr>"
      $('#stellar_transactions_table').append(trans_more_html)
    onerror: (error)->
      console.error("Error in payment stream")
  })


stellar_show_payments = ->
  stellar_server.payments()
    .limit(1)
    .call()
    .then (response)->
      # will follow the transactions link returned by Horizon
      response.records[0].transaction().then (txs)->
        console.log(txs)
        console.log( JSON.stringify(StellarSdk.xdr.TransactionEnvelope.fromXDR(txs.envelope_xdr, 'base64')) );
        console.log( JSON.stringify(StellarSdk.xdr.TransactionResult.fromXDR(txs.result_xdr, 'base64')) );
        console.log( JSON.stringify(StellarSdk.xdr.TransactionMeta.fromXDR(txs.result_meta_xdr, 'base64')) );
        $('#show_payments_result').html(JSON.stringify(StellarSdk.xdr.TransactionResult.fromXDR(txs.result_xdr, 'base64')))

stellar_account_transactions2 = (publickey)->
  console.log("stellar_account_transactions2 for: '" + publickey+"'.");
  $account_transactions = document.getElementById('stellar_account_transactions')
  trans_html = "<table><thead><th>Date</th><th>Amount</th><th>Asset</th><th>From</th></thead><div id=\"stellar_transactions_more\"></div></table>"
  $account_transactions.innerHTML = trans_html
  stellar_server.transactions()
    .forAccount(publickey)
    .call()
    .then (response) ->
      response.records.forEach (trans) ->
        console.log(trans)
        console.log(trans.created_at)
        console.log(trans.source_account)

stellar_account_transactions3 = (publickey)->
  console.log("stellar_account_transactions3 for: '" + publickey+"'.");
  $account_transactions = document.getElementById('stellar_account_transactions')
  trans_html = "<table id=\"stellar_transactions_table\"><thead><th>Date</th><th>Amount</th><th>Asset</th><th>From</th></thead><tbody></tbody></table>"
  $account_transactions.innerHTML = trans_html
  #trans_more_html = ''
  stellar_server.payments()
    .forAccount(publickey)
    .call()
    .then (response) ->
      response.records.forEach (trans) ->
        console.log(trans)
        console.log(trans.created_at)
        console.log(trans.source_account)
        asset = trans.asset_type
        if trans.amount
          amount = trans.amount
        else
          amount = trans.starting_balance
        #trans_more_html += "<tr><td>"+trans.created_at+"</td><td>"+amount+"</td><td>"+asset+"</td><td>"+trans.source_account+"</td></tr><div id=\"stellar_transactions_more\">"
        trans_more_html = "<tr><td>"+trans.created_at+"</td><td>"+amount+"</td><td>"+asset+"</td><td>"+trans.source_account+"</td></tr>"
        $('#stellar_transactions_table').append(trans_more_html)
    #.then ->
    #  console.log('in then clause - have: '+trans_more_html)
    #  $('#stellar_transactions_more').outerHTML = trans_more_html

stellar_handle_stream_trans = (txResponse)->
  console.log(txResponse)

stellar_stream_account_trans = ->
  lastCursor = 0    # or load where you left off
  stellar_server.transactions()
    .forAccount(accountAddress)
    .cursor(lastCursor)
    .stream ->
      onmessage: stellar_handle_stream_trans

setup_ledger_u2f = ->
  #import "babel-polyfill";
  #import TransportU2F from "@ledgerhq/hw-transport-u2f"
  #import AppStr from "@ledgerhq/hw-app-str"
  initial = "<h2>Connect your Ledger and open Stellar app. Click button start...</h2>"
  $main = document.getElementById("ledger_setup_result")
  $main.innerHTML = initial
  #document.body.addEventListener("click", async () ->
  $('#connect_to_ledger').click (event)->
    event.preventDefault()
    $main.innerHTML = initial
    try
      console.log('trying to setup transport.')
      #transport = await TransportU2F.create()
      transport = await TransportWebUSB.create()
      #transport.setDebugMode(true)
      console.log('trying to setup Stellar App.')
      appStr = new AppStr(transport)
      console.log('trying to get Stellar public key.')
      { stellarAddress } = await appStr.getWalletPublicKey(
        "44'/0'/0'/0/0",
        false )
      $main2 = document.getElementById("ledger_connect_result")
      h2 = document.createElement("h2")
      h2.textContent = stellarAddress
      $main.innerHTML = "<h1>Your first Stellar address:</h1>"
      $main.appendChild(h2)
      await appStr.getWalletPublicKey("44'/0'/0'/0/0", true)
    catch e
      $err = document.createElement("code")
      $err.style.color = "#f66"
      $err.textContent = String(e.message || e)
      $main.appendChild($err)
    finally
      console.log('all done.')

connect_ledger_u2f = ->
  console.log('connect_ledger_u2f - at start.')
  try
    console.log('trying to setup transport.')
    transport = await TransportU2F.create()
    console.log('got transport:')
    console.log(transport)
    #transport.setDebugMode(true)
    console.log('trying to setup Stellar App.')
    appStr = new AppStr(transport)
    console.log('got Stellar App:')
    console.log(appStr)
    result = await appStr.getAppConfiguration();
    console.log('got Stellar App configuration:')
    console.log(result)
    result2 = await appStr.getPublicKey("44'/148'/0'");
    console.log('got Stellar public key: ')
    console.log(result2)
    return result2.publicKey
  catch error
    console.log('got error on ledger connect u2f - error: '+error.toString())
    Rs.show_flash('alert', 'Could not connect to your Ledger device - please try again.')
    #$err = document.createElement("code")
    #$err.style.color = "#f66"
    #$err.textContent = String(e.message || e)
    #$main.appendChild($err)
    return ''
  finally
    console.log('ledger connect - all done.')

ledger_u2f_sign_transaction = (publicKey, transaction)->
  console.log('ledger_u2f_sign_transaction - at start - have transaction:')
  console.log(transaction)
  if message = $('#stellar_active').attr('data-ledger_connect_message')
    if !confirm(message)
      return
  # not sure if we should just continue or fail with some message
  try
    transport = await TransportU2F.create()
    console.log('got transport:')
    console.log(transport)
    #transport.setDebugMode(true)
    console.log('trying to setup Stellar App.')
    appStr = new AppStr(transport)
    console.log('got Stellar App:')
    console.log(appStr)
    result = await appStr.signTransaction("44'/148'/0'", transaction.signatureBase());
    # add signature to transaction
    keyPair = StellarSdk.Keypair.fromPublicKey(publicKey);
    hint = keyPair.signatureHint();
    decorated = new StellarSdk.xdr.DecoratedSignature({hint: hint, signature: result.signature});
    transaction.signatures.push(decorated);
    return transaction;
  catch error
    console.log('got error on ledger u2f_sign_transaction - error: '+error.toString())
  finally
    console.log('ledger u2f_sign_transaction - all done.')


connect_ledger_webusb = ->
  console.log('connect_ledger_webusb - at start.')
  try
    console.log('trying to setup webusb transport.')
    #transport = await TransportU2F.create()
    transport = await TransportWebUSB.create()
    console.log('got transport:')
    console.log(transport)
    transport.setDebugMode(true)
    console.log('trying to setup Stellar App.')
    appStr = new AppStr(transport)
    console.log('got Stellar App:')
    console.log(appStr)
    result = await appStr.getAppConfiguration();
    console.log('got Stellar App configuration:')
    console.log(result)
    result2 = await appStr.getPublicKey("44'/148'/0'");
    console.log('got Stellar public key: ')
    console.log(result2)
    return result2.publicKey()
    #console.log('trying to get Stellar public key.')
    #{ stellarAddress } = await appStr.getWalletPublicKey(
    # "44'/0'/0'/0/0",
    #  false )
    #console.log('got Stellar address: '+stellarAddress)

    $main2 = document.getElementById("ledger_connect_result")
    h2 = document.createElement("h2")
    h2.textContent = stellarAddress
    $main.innerHTML = "<h1>Your first Stellar address:</h1>"
    $main.appendChild(h2)
    await appStr.getWalletPublicKey("44'/0'/0'/0/0", true)
  catch error
    console.log('got error on ledger connect - error: '+error.toString())

    $err = document.createElement("code")
    $err.style.color = "#f66"
    #$err.textContent = String(e.message || e)
    #$main.appendChild($err)
  finally
    console.log('ledger connect - all done.')

stellar_account_wallet_show = (wallet_type) ->
  switch wallet_type
    when '1'
      console.log('wallet type 1')
      $('.generate_key_pair').show()
      $('.connect_ledger_u2f').hide()
      $('.connect_ledger_webusb').hide()
      $('.account_secret').show()
      $('.account_public_key').show()
      $('.account_public_key input').prop('disabled',false)
    when '2'
      console.log('wallet type 2')
      $('.generate_key_pair').hide()
      $('.connect_ledger_u2f').show()
      $('.connect_ledger_webusb').show()
      $('.account_secret').hide()
      $('.account_public_key').show()
      $('.account_public_key input').prop('disabled',true)
    when '3'
      console.log('wallet type 3')
      $('.generate_key_pair').hide()
      $('.connect_ledger_u2f').hide()
      $('.connect_ledger_webusb').hide()
      $('.account_secret').hide()
      $('.account_public_key').show()
      $('.account_public_key input').prop('disabled',true)
    else
      $('.generate_key_pair').hide()
      $('.connect_ledger_u2f').hide()
      $('.connect_ledger_webusb').hide()
      $('.account_secret').hide()
      $('.account_public_key').hide()

jQuery(document).on 'turbolinks:load', ->
  console.log('checking for stellar...')
  stellar_setup()
  stellar_account_setup()

  # stellar_account generate new key pair
  $('#generate_stellar_key_pair').click (event)->
    if message = $(this).attr('data-confirmtext')
      console.log('we have data-confirmtext: '+message)
      if message
        if !confirm(message)
          event.preventDefault()
          return

    event.preventDefault()
    try
      keypair1 = StellarSdk.Keypair.random()
      # see more about KeyPair objects: https://stellar.github.io/js-stellar-sdk/Keypair.html
      #console.log('Secret key (seed): '+keypair1.secret())
      console.log('Public key: '+keypair1.publicKey())
      $form = $(this).closest('form')
      $form.find("input[name='stellar_account[public_key]']").val(keypair1.publicKey())
      $form.find("input[name='stellar_account[secret]']").val(keypair1.secret())
    catch error
      console.log('got error generating stellar key pair - error: '+error.toString())

  $('#connect_ledger_u2f').click (event)->
    event.preventDefault()
    if message = $(this).attr('data-confirmtext')
      if !confirm(message)
        return
    publickey = await connect_ledger_u2f()
    if publickey
      $form = $(this).closest('form')
      $form.find("input[name='stellar_account[public_key]']").val(publickey)

  $('#connect_ledger_webusb').click (event)->
    if message = $(this).attr('data-confirmtext')
      #console.log('we have data-confirmtext: '+message)
      if message
        if !confirm(message)
          event.preventDefault()
          return
    event.preventDefault()
    connect_ledger_webusb()

  # stellar_account - show correct wallet details when changing wallet type (radio button)
  $('input[name="stellar_account[wallet_type]"]').change ->
    wallet_type = $('input[name="stellar_account[wallet_type]"]:checked').val()
    console.log('Wallet type changed: '+wallet_type)
    stellar_account_wallet_show(wallet_type)

  $('#stellar_testnet_fund_account').click (event)->
    if message = $(this).attr('data-confirmtext')
      if message
        if !confirm(message)
          event.preventDefault()
          return
    event.preventDefault()
    $result = $("#stellar_testnet_create_account .result")
    console.log('stellar_testnet_fund_account clicked - result: ')
    console.log($result)
    public_key = $('#stellar_active').attr('data-publickey')
    if !public_key
      console.log('stellar_testnet_fund_account: no public key.')
      $('#stellar_testnet_create_account .result').html('Please assign a Stellar Public Key first.')
      $().show_flash_notice('No public key on account - Please assign a Stellar Public Key first.')
    else
      #$result.innerHTML = '<p>Trying to fund your Stellar TestNet Account...</p>'
      res = stellar_testnet_friendbot_create_account(public_key)
      #$('#stellar_testnet_create_account .result').html(res)
      $().show_flash_notice('Successfully funded account (TestNet).')
      # trigger show of account balance
      $('#stellar_show_balance').trigger('click')

  $('form.new_stellar_account').submit (event)->
    $('.account_public_key input').prop('disabled',false)   # enable public_key so it gets send to server
    # just continue and send to server

  $('#stellar_show_balance').click (event)->
    event.preventDefault()
    public_key = $('#stellar_active').attr('data-publickey')
    stellar_account_balance(public_key)

  $('#stellar_show_transactions').click (event)->
    event.preventDefault()
    public_key = $('#stellar_active').attr('data-publickey')
    stellar_account_transactions(public_key)

  $('#show_payments').click (event)->
    event.preventDefault()
    stellar_show_payments()

  $('#setup_ledger').click (event)->
    event.preventDefault()
    setup_ledger_u2f()

  $('form.stellar_tokens_create_tokens').submit (event) ->
    $this = $(this)
    event.preventDefault()
    if message = $this.attr('data-confirmtext')
      if message
        if !confirm(message)
          return false
    console.log('stellar create token - at start.')
    $stellar_active = $('#stellar_active')
    token_name = $stellar_active.attr('data-token_name')
    console.log('stellar create_tokens - token name: '+token_name.toString())
    amount = $this.find('#stellar_token_amount').val()
    console.log('stellar create_tokens - amount: '+amount.toString())
    distributor_publickey = $stellar_active.attr('data-distributor_publickey')
    distributor_wallet_type = $stellar_active.attr('data-distributor_wallet_type')
    distributor_signer_url = $stellar_active.attr('data-distributor_signer_url')
    issuer_publickey = $stellar_active.attr('data-issuer_publickey')
    issuer_wallet_type = $stellar_active.attr('data-issuer_wallet_type')
    issuer_signer_url = $stellar_active.attr('data-issuer_signer_url')
    stellar_create_token(distributor_publickey, issuer_publickey, token_name, amount, distributor_wallet_type, distributor_signer_url, issuer_wallet_type, issuer_signer_url)
    false

  $('form.stellar_account_trust_token').submit (event) ->
    $this = $(this)
    if message = $this.attr('data-confirmtext')
      if message
        if !confirm(message)
          event.preventDefault()
          return false
    event.preventDefault()
    selected_token_id = $this.find('#stellar_account_stellar_token_id').val()
    console.log('stellar trust token - at start - selected token = '+selected_token_id.toString())
    selected_token_name = $this.find('#stellar_account_stellar_token_id option:selected').text()
    console.log('stellar trust token - selected token name = '+selected_token_name)
    #get_token_issuer_url = $this.attr('data-stellar_token_url')
    trust_token_url = $this.attr('action')
    account_signer_url = trust_token_url.replace('trust_token', 'sign_transaction')
    #get_issuer_public_key_url = get_token_issuer_url.replace('TOKENID',selected_token_id.toString())
    #issuer_publickey = await stellar_token_get_issuer(get_issuer_public_key_url)
    #console.log('stellar trust token - got issuer public key = '+issuer_publickey)
    $stellar_active = $('#stellar_active')
    account_publickey = $stellar_active.attr('data-publickey')
    account_wallet_type = $stellar_active.attr('data-wallet_type')
    stellar_trust_token(account_publickey, selected_token_id, account_wallet_type, account_signer_url)
    false

  # make payment / send tokens from winery admin
  $('form.stellar_account_make_payment').submit (event)->
    $this = $(this)
    event.preventDefault()
    if message = $this.attr('data-confirmtext')
      if message
        if !confirm(message)
          return false
    console.log('stellar make_payment - at start.')
    destinationId = $this.find('#stellar_account_destination_id').val()
    console.log('stellar make_payment - destinationId: '+destinationId.toString())
    amount = $this.find('#stellar_account_payment_amount').val()
    console.log('stellar make_payment - amount: '+amount.toString())
    selected_token_id = $this.find('#stellar_account_stellar_token_id').val()
    console.log('stellar make_payment - selected token = '+selected_token_id.toString())
    selected_token_name = $this.find('#stellar_account_stellar_token_id option:selected').text()
    console.log('stellar make_payment - selected token name = '+selected_token_name)

    make_payment_url = $this.attr('action')
    account_signer_url = make_payment_url.replace('make_payment', 'sign_transaction')

    $stellar_active = $('#stellar_active')
    account_publickey = $stellar_active.attr('data-publickey')
    account_wallet_type = $stellar_active.attr('data-wallet_type')

    stellar_make_payment(account_publickey, destinationId, selected_token_id, amount, 'MB Test payment', account_wallet_type, account_signer_url)
    false

  # make payment / send tokens from front (contact)
  $('form.stellar_account_front_make_payment').submit (event)->
    $this = $(this)
    event.preventDefault()
    if message = $this.attr('data-confirmtext')
      if message
        if !confirm(message)
          return false
    console.log('stellar front_make_payment - at start.')
    destinationId = $this.find('#stellar_account_destination_id').val()
    console.log('stellar make_payment - destinationId: '+destinationId.toString())
    amount = $this.find('#stellar_account_payment_amount').val()
    console.log('stellar make_payment - amount: '+amount.toString())
    selected_token_id = $this.find('#stellar_account_stellar_token_id').val()
    console.log('stellar make_payment - selected token = '+selected_token_id.toString())
    selected_token_name = $this.find('#stellar_account_stellar_token_id option:selected').text()
    console.log('stellar make_payment - selected token name = '+selected_token_name)

    make_payment_url = $this.attr('action')
    account_signer_url = make_payment_url.replace('make_payment', 'sign_transaction')

    $stellar_active = $('#stellar_active')
    account_publickey = $stellar_active.attr('data-publickey')
    account_wallet_type = $stellar_active.attr('data-wallet_type')

    stellar_make_payment(account_publickey, destinationId, selected_token_id, amount, 'MB Test payment', account_wallet_type, account_signer_url)
    false

#Override the default confirm dialog by rails
#  $.rails.allowAction = function(link){
#    if (link.data("confirm") == undefined){
#      return true;
#  }
#  $.rails.showConfirmationDialog(link);
#  return false;
#  }
#  //User click confirm button
#  $.rails.confirmed = function(link){
#    link.data("confirm", null);
#  link.trigger("click.rails");
#  }
#  //Display the confirmation dialog
#  $.rails.showConfirmationDialog = function(link){
#    var message = link.data("confirm");
#  $.fn.SimpleModal({
#    model: "modal",
#    title: "Please confirm",
#    contents: message
#  }).addButton("Confirm", "button alert", function(){
#    $.rails.confirmed(link);
#  this.hideModal();
#  }).addButton("Cancel", "button secondary").showModal();
#  }

#  const getStrAppVersion = async () => {
#    const transport = await Transport.create();
#    const str = new Str(transport);
#  const result = await str.getAppConfiguration();
#  return result.version;
#  }
#  getStrAppVersion().then(v => console.log(v));
#
#  const getStrPublicKey = async () => {
#    const transport = await Transport.create();
#    const str = new Str(transport);
#  const result = await str.getPublicKey("44'/148'/0'");
#  return result.publicKey;
#  };
#  let publicKey;
#  getStrPublicKey().then(pk => {
#    console.log(pk);
#    publicKey = pk;
#  });
#
#  const signStrTransaction = async (publicKey) => {
#    const transaction = new StellarSdk.TransactionBuilder({accountId: () => publicKey, sequenceNumber: () => '1234', incrementSequenceNumber: () => null})
#      .addOperation(StellarSdk.Operation.createAccount({
#        source: publicKey,
#        destination: 'GBLYVYCCCRYTZTWTWGOMJYKEGQMTH2U3X4R4NUI7CUGIGEJEKYD5S5OJ', // SATIS5GR33FXKM7HVWZ2UQO33GM66TVORZUEF2HPUQ3J7K634CTOAWQ7
#      startingBalance: '11.331',
#  }))
#  .build();
#  const transport = await Transport.create();
#  const str = new Str(transport);
#  const result = await str.signTransaction("44'/148'/0'", transaction.signatureBase());
#
#  // add signature to transaction
#  const keyPair = StellarSdk.Keypair.fromPublicKey(publicKey);
#  const hint = keyPair.signatureHint();
#  const decorated = new StellarSdk.xdr.DecoratedSignature({hint: hint, signature: result.signature});
#  transaction.signatures.push(decorated);
#
#  return transaction;
#  }
#  signStrTransaction(publicKey).then(transaction => console.log(transaction.toEnvelope().toXDR().toString('base64')));

