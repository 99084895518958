//= require jsuri

//
//  Rs  (Rapid solucions) namespace
//

window.Rs = {};

Rs.ready = function(callback) {
    if (typeof Turbolinks !== 'undefined' && Turbolinks.supported) {
        jQuery(document).on('turbolinks:load', function() {
            callback(jQuery);
        });
    } else {
        jQuery(document).ready(callback);
    }
};

//Rs.mountedAt = function() {
//    return "<%= Rails.application.routes.url_helpers.rs_path(trailing_slash: true) %>";
//};

Rs.wineryPath = function() {
    var locationPath;
    locationPath = window.location.pathname;
    console.log('Rs.wineryPath called - locationPath: '+locationPath);
    var wineryPath = locationPath.match(/\/wineries\/\d+\//);
    return wineryPath;
};

Rs.pathFor = function(path) {
    var locationOrigin;
    locationOrigin = (window.location.protocol + "//" + window.location.hostname) + (window.location.port ? ":" + window.location.port : "");
    console.log('Rs.pathFor called - locationOrigin: '+locationOrigin+', path: '+path);
    return locationOrigin + Rs.wineryPath() + path;
};

Rs.apiPathFor = function(path) {
    var locationOrigin;
    locationOrigin = (window.location.protocol + "//" + window.location.hostname) + (window.location.port ? ":" + window.location.port : "");
    console.log('Rs.pathFor called - locationOrigin: '+locationOrigin+', path: '+path);
    return locationOrigin + '/api' + Rs.wineryPath() + path;
};

Rs.url = function(uri, query) {
    if (console && console.warn) {
        console.warn('Rs.url is deprecated, and will be removed from a future Solidus version.');
    }
    if (uri.path === undefined) {
        uri = new Uri(uri);
    }
    if (query) {
        $.each(query, function(key, value) {
            return uri.addQueryParam(key, value);
        });
    }
    return uri;
};

Rs.ajax = function(url, options) {
    if (typeof url === "object") {
        options = url;
        url = undefined;
    }
    options = options || {};
    options = $.extend(options, {
        headers: {
            'Authorization': 'Bearer ' + Rs.api_key
        }
    });
    console.log('Rs.ajax called - url: '+url+', options: '+options);
    return $.ajax(url, options);
};


//var frontend_routes = {
//    states_search: Rs.pathFor('api/states'),
//    apply_coupon_code: function(order_id) {
//        return Rs.pathFor("api/orders/" + order_id + "/coupon_codes");
//    }
//}

//if(typeof Proxy == "function") {
//    Rs.routes = new Proxy(frontend_routes, Rs.routesDeprecationProxy);
//} else {
//    Rs.routes = frontend_routes
//}

Rs.getJSON = function(url, data, success) {
    if (typeof data === 'function') {
        success = data;
        data = undefined;
    }
    return Rs.ajax({
        dataType: "json",
        url: url,
        data: data,
        success: success
    });
};

function show_flash(message, message_type, container) {
    if (! message) return;
    var container = container || $('.flash_notifications');
    var msg_div = $('<div data-alert class="alert-box '+message_type+'">'+message+'</div>');
    container.append(msg_div);
    setTimeout(function() {
        msg_div.fadeOut('fast', function() {
            $(this).remove();
        });
    }, 15000);
};

Rs.show_flash = function(typ, mess) {
    console.log('Rs.show_flash called - type: '+typ+', mess: '+mess);
    show_flash(mess, typ);
};