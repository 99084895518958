
jQuery(document).on 'turbolinks:load', ->

  $("a.link_with_select").click (event)->
    #event.preventDefault()
    parent = $(this).closest(".input-group")
    #console.log('click on link_with_select')
    #console.log(parent)
    selected_val = parent.find("select").val()
    console.log(selected_val)
    link_val = $(this).attr("data-href")
    link_val = link_val.replace("xxx",selected_val)
    $(this).attr("href", link_val)