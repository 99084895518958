

render_calculator_fields = (elem) ->
  selected_class = elem.find('.js-calculator-type option:selected').val()
  console.log('got selected_class: '+selected_class)
  elem.find('.js-calculator-preferences').each ->
    # console.log('got elem: '+$(this).data('calculator-type'))
    selected = ($(this).data('calculator-type') == selected_class)
    $(this).find(':input').prop("disabled", !selected);
    $(this).toggle(selected);



jQuery(document).on 'turbolinks:load', ->
  $('.js-calculator-fields').each ->
    elem = $(this)
    render_calculator_fields(elem)

  # show correct payment method details when changing payment method (select field)
  $('.js-calculator-type').change ->
    elem = $(this).closest('.js-calculator-fields')
    render_calculator_fields(elem)

